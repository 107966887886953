import React from 'react';

export const Progress = ({ value = 0, className = '', isDarkMode = false, ...props }) => (
  <div 
    className={`relative h-2 w-full overflow-hidden rounded-full ${
      isDarkMode ? 'bg-gray-700' : 'bg-gray-200'
    } ${className}`}
    {...props}
  >
    <div 
      className="h-full bg-primary transition-all"
      style={{ 
        width: `${value}%`,
        backgroundColor: isDarkMode ? '#60a5fa' : '#3b82f6'
      }}
    />
  </div>
);