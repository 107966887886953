import React from "react";
import Ecard from '../../../components/cards/Ecard';
import useInView from '../../../components/inView/useInView';



const ExploringSection = () => {
    const [setRef, inView] = useInView({
        threshold: 0.1, // Trigger when the section is at least 10% in view
      });

    return (
        <section 
        className={`exploring-section ${inView ? 'in-view' : ''}`} ref={setRef}>            
        <div className="header text-center">
                <h1 className='mb-4 '>Let's explore the horizon where AI meets humanity - <br /> a journey into the future, together.</h1>
                <p className="caption">Revolutionizing tasks with AI. Meet AXN, Alice and Hannah, your AI team. They're experts' top pick, and soon, yours too. </p>
            </div>
            <div className="body mt-6">
                <div className="row">
                    <Ecard
                        icon="assets/icons/alice_1.png"
                        heading="Alice: Meetings"
                        desc="Introducing Alice, your personal meeting assistant! Alice attends meetings on your behalf, taking notes and sharing summaries. With Alice by your side, you can take a break or focus on other tasks while she records, summarizes, and even allows you to ask specific questions later. Streamline your meetings with Alice! Currently supports Google and WebEx meetings."
                    />
                    <Ecard
                        icon="assets/icons/whatsapp_1.png"
                        heading="Hannah: WhatsApp"
                        desc="Meet Hannah, your all-knowing personal agent! Need help with anything? Questions? Problems? Research? Hannah has you covered. Communicating with Hannah is effortless – send voice notes in over 100 languages. No matter your language, Hannah's got your back." />
                    <Ecard
                        icon="assets/icons/video_1.png"
                        heading="Ray: Audio/Youtube"
                        desc="Ray makes listening to your audio transcriptions like having a chat, where AI helps you talk to the words and understand them better. It's like your recordings come alive, making learning and understanding way more fun and easy!" />
                    <Ecard
                        icon="assets/icons/upload_1.png"
                        heading="Dori: Documents"
                        desc="Introducing Dori, your document summarizing agent! AXN saves you time by summarizing long documents and answering any questions you have about them. No more sifting through every word – AXN gets straight to the point, giving you the information you need quickly and efficiently." />
                </div>
            </div>
        </section>
    );
}

export default ExploringSection;