import nlp from 'compromise';
import {
  Activity,
  Clock,
  FileText,
  TrendingUp
} from 'lucide-react';
import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { Cell, Pie, PieChart, Tooltip as RechartsTooltip, ResponsiveContainer } from 'recharts';
import HelpContent from '../../../../../components/dashboardheader/HelpContent';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Progress } from '../components/ui/progress';
import './IntelligenceDashboard.css';

import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const TIME_RANGES = {
  WEEK: 'week',
  MONTH: 'month',
  QUARTER: 'quarter',
  ALL: 'all'
};

const IntelligenceDashboard = ({ isDarkMode, transcriptions }) => {
  const summaries = useMemo(() => {
    const sortedSummaries = transcriptions
      .filter((transcription) => transcription.summary)
      .map((transcription) => {
        const [day, month, year] = transcription.date.split('/').map(Number);
        const [hour, minute] = transcription.time.split(':').map(Number);
        const fullYear = year < 100 ? 2000 + year : year;
        const timestamp = new Date(fullYear, month - 1, day, hour, minute);

        return {
          ...transcription,
          timestamp,
        };
      })
      .sort((a, b) => b.timestamp - a.timestamp);

    return sortedSummaries;
  }, [transcriptions]);

  const totalSummaries = summaries.length;
  const totalWordCount = useMemo(() => {
    return summaries.reduce((acc, summary) => {
      const wordCount = summary.summary.split(' ').length;
      return acc + wordCount;
    }, 0);
  }, [summaries]);

  const averageSummaryLength = useMemo(() => {
    if (summaries.length === 0) return 0;
    return Math.round(totalWordCount / summaries.length);
  }, [totalWordCount, summaries.length]);

  const fileTypeData = useMemo(() => {
    const types = transcriptions.reduce((acc, trans) => {
      const fileType = trans.audioName.split('.').pop().toLowerCase();
      const type = {
        'docx': 'Document',
        'pdf': 'Document',
        'mp4': 'Video',
        'mp3': 'Audio',
      }[fileType] || 'Other';
      
      acc[type] = (acc[type] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(types).map(([name, value]) => ({ name, value }));
  }, [transcriptions]);

  const productivityScore = useMemo(() => {
    if (transcriptions.length === 0) return 0;
    const factors = {
      consistencyScore: Math.min(transcriptions.length / 30 * 100, 100),
      completionRate: transcriptions.filter(t => t.summary).length / transcriptions.length * 100,
      diversityScore: Object.keys(fileTypeData).length * 25
    };
    return Math.round((factors.consistencyScore + factors.completionRate + factors.diversityScore) / 3);
  }, [transcriptions, fileTypeData]);

  const timeSaved = useMemo(() => {
    const minutes = transcriptions.length * 30;
    return {
      hours: Math.floor(minutes / 60),
      minutes: minutes % 60
    };
  }, [transcriptions]);

  const stripHtmlTags = (str) => {
    if (!str) return '';
    return str.replace(/<[^>]*>/g, '');
  };

  const extractActionItems = (summary) => {
    const cleanSummary = stripHtmlTags(summary);
    const actionItems = extractFromSections(cleanSummary, ['Action Items', 'Next Steps']);
    if (actionItems.length > 0) return actionItems;

    const doc = nlp(cleanSummary);
    const sentences = doc.sentences();
    const actionSentences = sentences.filter((sent) => {
      const text = sent.text();
      return (
        sent.match('^#Verb').has('#Imperative') ||
        sent.has('(need to|must|ensure|require|should|have to|make sure to|remember to)') ||
        /^\s*-\s*/.test(text)
      );
    });
    
    return actionSentences.out('array');
  };

  const extractTips = (summary) => {
    const cleanSummary = stripHtmlTags(summary);
    const tips = extractFromSections(cleanSummary, ['Tips', 'Recommendations']);
    if (tips.length > 0) return tips;

    const doc = nlp(cleanSummary);
    const sentences = doc.sentences();
    const tipSentences = sentences.filter((sent) => {
      return (
        sent.has('(should|could|recommend|suggest|consider|advise|it is recommended|best to|optimal to)') ||
        sent.text().startsWith('Tip:')
      );
    });
    
    return tipSentences.out('array');
  };

  const extractFromSections = (text, sectionHeadings) => {
    const lines = text.split('\n');
    let items = [];
    let capture = false;
    
    lines.forEach((line) => {
      const trimmedLine = line.trim();
      if (new RegExp(`^(${sectionHeadings.join('|')}):?$`, 'i').test(trimmedLine)) {
        capture = true;
        return;
      }
      if (/^[A-Z][A-Za-z\s]+:?$/.test(trimmedLine) && !new RegExp(`^(${sectionHeadings.join('|')}):?$`, 'i').test(trimmedLine)) {
        capture = false;
        return;
      }
      if (capture && trimmedLine !== '') {
        items.push(trimmedLine);
      }
    });
    
    return items;
  };

  const actionItems = useMemo(() => {
    return summaries.flatMap((summaryObj) => {
      const actionSentences = extractActionItems(summaryObj.summary);
      return actionSentences.map((sentence) => ({
        sentence,
        title: summaryObj.audioName,
        date: summaryObj.date,
        time: summaryObj.time,
      }));
    });
  }, [summaries]);

  const tips = useMemo(() => {
    return summaries.flatMap((summaryObj) => {
      const tipSentences = extractTips(summaryObj.summary);
      return tipSentences.map((sentence) => ({
        sentence,
        title: summaryObj.audioName,
        date: summaryObj.date,
        time: summaryObj.time,
      }));
    });
  }, [summaries]);

  const chartData = useMemo(() => {
    const dateCounts = {};
    summaries.forEach((summary) => {
      const date = summary.timestamp;
      const monthYear = `${date.getMonth() + 1}/${date.getFullYear()}`;
      dateCounts[monthYear] = (dateCounts[monthYear] || 0) + 1;
    });
    
    const sortedLabels = Object.keys(dateCounts).sort((a, b) => {
      const [monthA, yearA] = a.split('/').map(Number);
      const [monthB, yearB] = b.split('/').map(Number);
      return yearA - yearB || monthA - monthB;
    });
    
    return {
      labels: sortedLabels,
      datasets: [
        {
          label: 'Number of Summaries',
          data: sortedLabels.map(label => dateCounts[label]),
          backgroundColor: 'rgba(75,192,192,0.6)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1,
        },
      ],
    };
  }, [summaries]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Summaries by Month'
      }
    }
  };

  const enhancedTimeSavings = useMemo(() => {
    const breakdown = summaries.reduce((acc, summary) => {
      const fileType = summary.audioName.split('.').pop().toLowerCase();
      const wordCount = summary.summary.split(' ').length;
      
      const timesSaved = {
        'mp4': Math.round(wordCount / 100 * 3),
        'mp3': Math.round(wordCount / 120 * 3),
        'pdf': Math.round(wordCount / 200 * 2),
        'docx': Math.round(wordCount / 200 * 2)
      };

      const savedMinutes = timesSaved[fileType] || Math.round(wordCount / 150 * 2);
      acc.totalMinutes += savedMinutes;
      acc.byFileType[fileType] = (acc.byFileType[fileType] || 0) + savedMinutes;
      
      return acc;
    }, {
      totalMinutes: 0,
      byFileType: {}
    });

    const hourlySavings = (breakdown.totalMinutes / 60) * 100;
    
    return {
      ...breakdown,
      costSavings: Math.round(hourlySavings),
      monthlySavings: Math.round(hourlySavings / (summaries.length || 1) * 30)
    };
  }, [summaries]);

  const decisionMetrics = useMemo(() => {
    const actionItemsPerDay = actionItems.reduce((acc, item) => {
      const date = item.date;
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});

    const avgActionItems = Object.values(actionItemsPerDay).reduce((a, b) => a + b, 0) / 
      Object.keys(actionItemsPerDay).length || 0;

    return {
      avgActionItemsPerSummary: (actionItems.length / summaries.length).toFixed(1),
      avgDecisionTime: Math.round(avgActionItems * 5),
      actionItemTrend: Math.round((avgActionItems / 3) * 100),
      decisionSpeed: Math.round((avgActionItems / (summaries.length || 1)) * 100)
    };
  }, [actionItems, summaries]);

  const productivityAnalytics = useMemo(() => {
    const dailyStats = summaries.reduce((acc, summary) => {
      const date = summary.date;
      if (!acc[date]) {
        acc[date] = {
          count: 0,
          wordCount: 0,
          actionItems: 0
        };
      }
      acc[date].count++;
      acc[date].wordCount += summary.summary.split(' ').length;
      acc[date].actionItems += 1;

      return acc;
    }, {});

    const metrics = Object.values(dailyStats);
    const avgDailyCount = metrics.reduce((acc, day) => acc + day.count, 0) / metrics.length;
    const peakProductivity = Math.max(...metrics.map(day => day.count));

    return {
      consistencyScore: Math.round((avgDailyCount / peakProductivity) * 100),
      productivityTrend: metrics.map(day => ({
        count: day.count,
        efficiency: (day.actionItems / day.count) * 100
      })),
      peakPerformance: {
        summaries: peakProductivity,
        date: Object.entries(dailyStats)
          .find(([_, stats]) => stats.count === peakProductivity)?.[0]
      }
    };
  }, [summaries]);

  const engagementMetrics = useMemo(() => {
    const activeUsers = new Set(summaries.map(s => s.date)).size;
    const dailyEngagement = summaries.reduce((acc, summary) => {
      const date = summary.date;
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});

    return {
      activeDays: activeUsers,
      consistentUsage: Math.round((activeUsers / 30) * 100),
      engagementScore: Math.round(
        ((Object.keys(dailyEngagement).length / 30) + 
         (summaries.length / 100)) * 50
      ),
      frequency: Object.values(dailyEngagement)
        .reduce((acc, count) => acc + count, 0) / Object.keys(dailyEngagement).length
    };
  }, [summaries]);

  const qualityMetrics = useMemo(() => {
    return {
      averageWordCount: Math.round(totalWordCount / summaries.length),
      completionRate: Math.round((summaries.length / transcriptions.length) * 100),
      insightDensity: Math.round(((actionItems.length + tips.length) / summaries.length) * 10),
      efficiencyScore: Math.round(
        (totalWordCount / (summaries.length * 15)) * 100
      )
    };
  }, [summaries, transcriptions, totalWordCount, actionItems, tips]);

  const efficiencyMetrics = useMemo(() => [
    {
      name: 'Key Points',
      value: summaries.reduce((acc, s) => acc + (s.summary.split('.').length), 0),
      icon: <FileText className="w-4 h-4" />,
      trend: '+12% from last month'
    },
    {
      name: 'Topic Coverage',
      value: Math.round((actionItems.length + tips.length) / summaries.length * 100),
      icon: <Activity className="w-4 h-4" />,
      suffix: '%',
      trend: '+5% from last month'
    },
    {
      name: 'Processing Rate',
      value: Math.round(totalWordCount / summaries.length),
      icon: <TrendingUp className="w-4 h-4" />,
      suffix: ' words',
      trend: '8% faster than average'
    }
  ], [summaries, actionItems, tips, totalWordCount]);

  const usagePatterns = useMemo(() => {
    const patterns = summaries.reduce((acc, summary) => {
      const hour = summary.timestamp.getHours();
      const period = 
        hour < 12 ? 'Morning (5-12)' : 
        hour < 17 ? 'Afternoon (12-5)' : 
        'Evening (5-12)';
      acc[period] = (acc[period] || 0) + 1;
      return acc;
    }, {});
    
    return Object.entries(patterns)
      .map(([name, value]) => ({ 
        name, 
        value,
        description: `Number of summaries created during ${name.toLowerCase()}`
      }))
      .sort((a, b) => b.value - a.value);
  }, [summaries]);
  const getMetricDescription = (metricName) => {
    const descriptions = {
      'Key Points': 'Total count of distinct points extracted from summaries, indicating the breadth of information captured.',
      'Topic Coverage': 'Percentage of content containing actionable insights, showing how information-rich your summaries are.',
      'Processing Rate': 'Average words processed per summary, indicating the depth and complexity of content analysis.',
      'Engagement Score': 'Combined measure of user interaction and system utilization, reflecting overall platform engagement.',
      'Active Days': 'Number of days with summary activity in the last 30 days, showing usage consistency.',
      'Daily Frequency': 'Average number of summaries processed on active days, indicating usage intensity.',
      'Usage Patterns': 'Distribution of summary creation across morning, afternoon, and evening, helping identify peak productivity periods.',
      
      'Time & Cost Savings': 'Total time saved through automated processing, with estimated cost savings based on processing efficiency. Monthly projections help track financial benefits.',
      'Quality Score': 'Measure of summary quality based on efficiency and insight density, showing how well the system captures important information.',
      'Decision Efficiency': 'Average number of actionable items identified per summary, indicating how effectively the system extracts key decisions and tasks.',
      'Performance Overview': 'Visual representation of summary creation over time, with efficiency metrics and action counts to track productivity patterns.',
      'Action Items': 'Automatically extracted tasks and required actions from your content, organized by source and timestamp.',
      'Tips & Recommendations': 'Key suggestions and recommendations identified from your content, helping capture important advice and guidance.'
    };
    return descriptions[metricName] || '';
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  return (
    <div className={`intelligence-dashboard ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
      {summaries.length === 0 ? (
        <>
          <h1 className="welcome-title">
            Welcome to AXN.ai, let's help you get started with your AXN journey
          </h1>
          <HelpContent />
        </>
      ) : (
        <div className="dashboard-content">
          <h1>AXN Dashboard</h1>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">

          {/* Key Metrics Section */}
          <section className="dashboard-section">
            <h2 className="section-title">Key Performance Metrics</h2>
            <div className="stats-grid ">
              <Card isDarkMode={isDarkMode} className="stat-card">
                <CardHeader isDarkMode={isDarkMode}>
                  <CardTitle isDarkMode={isDarkMode} className="flex items-center gap-2">
                    <Activity className="w-4 h-2" />
                    Productivity Score
                  </CardTitle>
                </CardHeader>
                <CardContent isDarkMode={isDarkMode}>
                  <div className="flex flex-col">
                    <p className="text-3xl font-bold">{productivityScore}%</p>
                    <p className="text-sm">
                      {productivityAnalytics.consistencyScore}% consistency rate
                    </p>
                    <Progress value={productivityScore} className="mt-2" />
                    <p className="text-xs mt-1 text-muted-foreground italic">
                    Overall efficiency measure combining consistency, completion rate, and content diversity. Higher percentage indicates better overall performance.
                  </p>
                  </div>
                </CardContent>
              </Card>

              <Card isDarkMode={isDarkMode} className="stat-card">
                <CardHeader isDarkMode={isDarkMode}>
                  <CardTitle isDarkMode={isDarkMode} className="flex items-center gap-2">
                    <Clock className="w-4 h-4" />
                    Time & Cost Savings
                  </CardTitle>
                </CardHeader>
                <CardContent isDarkMode={isDarkMode}>
                  <div className="flex flex-col">
                    <p className="text-3xl font-bold">
                      {timeSaved.hours}h {timeSaved.minutes}m
                    </p>
                    <p className="text-sm">
                      ${enhancedTimeSavings.costSavings} estimated savings
                    </p>
                    <div className="mt-2 text-xs">
                     <p className="text-sm">
                     Monthly projection: ${enhancedTimeSavings.monthlySavings}
                      </p>
                    </div>
                    <p className="text-xs mt-1 text-muted-foreground italic">
                    Total time saved through automated processing, with estimated cost savings based on processing efficiency. Monthly projections help track financial benefits.
                  </p>
                  </div>
                  
                </CardContent>
              </Card>

              <Card isDarkMode={isDarkMode} className="stat-card">
                <CardHeader isDarkMode={isDarkMode}>
                  <CardTitle isDarkMode={isDarkMode} className="flex items-center gap-2">
                    <TrendingUp className="w-4 h-4" />
                    Decision Efficiency
                  </CardTitle>
                </CardHeader>
                <CardContent isDarkMode={isDarkMode}>
                  <div className="flex flex-col">
                    <p className="font-bold">
                      {decisionMetrics.avgActionItemsPerSummary} actions per summary
                    </p>
                    <p className="text-sm text-muted-foreground italic">
                     Average number of actionable items identified per summary, indicating how effectively the system extracts key decisions and tasks.
                    </p>
                    <Progress value={decisionMetrics.decisionSpeed} className="mt-2" />
                  </div>
                </CardContent>
              </Card>

              <Card isDarkMode={isDarkMode} className="stat-card">
                <CardHeader isDarkMode={isDarkMode}>
                  <CardTitle isDarkMode={isDarkMode} className="flex items-center gap-2">
                    <FileText className="w-4 h-4" />
                    Quality Score
                  </CardTitle>
                </CardHeader>
                <CardContent isDarkMode={isDarkMode}>
                  <div className="flex flex-col">
                    <p className="text-3xl font-bold">
                      {qualityMetrics.efficiencyScore}%
                    </p>
                    <p className="text-sm">
                      {qualityMetrics.insightDensity} insights per summary
                    </p>
                    <Progress value={qualityMetrics.completionRate} className="mt-2" />
                    <p className="text-sm text-muted-foreground italic">
                    Measure of summary quality based on efficiency and insight density, showing how well the system captures important information.
                    </p>
                  </div>
                </CardContent>
              </Card>
            </div>
          </section>

          {/* Activity Trends Section */}
          <section className="dashboard-section">
            <h2 className="section-title">Activity Trends</h2>
            <Card isDarkMode={isDarkMode} className="chart-container">
              <CardHeader isDarkMode={isDarkMode}>
                <CardTitle isDarkMode={isDarkMode} className="flex items-center gap-2">
                  <TrendingUp className="w-4 h-4" />
                  Performance Overview
                </CardTitle>
              </CardHeader>
              <CardContent isDarkMode={isDarkMode}>
                <div style={{ height: '400px' }}>
                  <Bar 
                    data={chartData} 
                    options={{
                      ...chartOptions,
                      interaction: {
                        mode: 'index',
                        intersect: false,
                      },
                      plugins: {
                        tooltip: {
                          callbacks: {
                            label: function(context) {
                              const label = context.dataset.label || '';
                              const value = context.parsed.y;
                              return `${label}: ${value}`;
                            },
                            afterLabel: function(context) {
                              const date = chartData.labels[context.dataIndex];
                              const metrics = productivityAnalytics.productivityTrend[context.dataIndex];
                              return [
                                `Efficiency: ${metrics?.efficiency?.toFixed(1)}%`,
                                `Actions: ${metrics?.count || 0}`
                              ];
                            }
                          }
                        }
                      }
                    }} 
                  />
                </div>
              </CardContent>
            </Card>
          </section>

          {/* Insights Section */}
          <section className="dashboard-section insights-section">
            <h2 className="section-title">Key Insights</h2>
            <div className="stacked-layout">
              {/* Action Items */}
              <Card isDarkMode={isDarkMode} className="insights-card">
                <CardHeader isDarkMode={isDarkMode} className="sticky top-0 bg-card z-10">
                  <CardTitle isDarkMode={isDarkMode} className="flex items-center gap-2">
                    <FileText className="w-4 h-4" />
                    Action Items
                  </CardTitle>
                </CardHeader>
                <CardContent isDarkMode={isDarkMode} className="scrollable-content">
                  {actionItems && actionItems.length > 0 ? (
                    <div className="space-y-6">
                      {Object.entries(
                        actionItems.reduce((acc, item) => {
                          const key = `${item.title}_${item.date}_${item.time}`;
                          if (!acc[key]) {
                            acc[key] = {
                              title: item.title,
                              date: item.date,
                              time: item.time,
                              items: [],
                            };
                          }
                          acc[key].items.push(item.sentence);
                          return acc;
                        }, {})
                      ).map(([key, group]) => (
                        <div
                          key={key}
                          className={`p-4 rounded-lg border ${
                            isDarkMode
                              ? 'bg-gray-800 border-gray-700'
                              : 'bg-white '
                          } hover:shadow-md transition-shadow`}
                        >
                          <div className="flex justify-between items-start mb-3">
                            <strong className="text-sm">{group.title}</strong>
                            <span className="text-xs text-muted-foreground">
                              <br/>{group.date} {group.time}
                            </span>
                          </div>
                          <ul className="space-y-2 list-disc pl-5">
                            {group.items.map((sentence, idx) => (
                              <li key={idx} className="text-sm">
                                {sentence}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>No action items found in recent summaries.</p>
                  )}
                </CardContent>
              </Card>

              {/* Tips & Recommendations */}
              <Card isDarkMode={isDarkMode} className="insights-card">
                <CardHeader isDarkMode={isDarkMode} className="sticky top-0 bg-card z-10">
                  <CardTitle isDarkMode={isDarkMode} className="flex items-center gap-2">
                    <TrendingUp className="w-4 h-4" />
                    Tips & Recommendations
                  </CardTitle>
                </CardHeader>
                <CardContent isDarkMode={isDarkMode} className="scrollable-content">
                  {tips && tips.length > 0 ? (
                    <div className="space-y-6">
                      {Object.entries(
                        tips.reduce((acc, tip) => {
                          const key = `${tip.title}_${tip.date}_${tip.time}`;
                          if (!acc[key]) {
                            acc[key] = {
                              title: tip.title,
                              date: tip.date,
                              time: tip.time,
                              items: [],
                            };
                          }
                          acc[key].items.push(tip.sentence);
                          return acc;
                        }, {})
                      ).map(([key, group]) => (
                        <div
                          key={key}
                          className={`p-4 rounded-lg border ${
                            isDarkMode
                              ? 'bg-gray-800 border-gray-700'
                              : 'bg-white border-gray-200'
                          } hover:shadow-md transition-shadow`}
                        >
                          <div className="flex justify-between items-start mb-3">
                            <strong className="text-sm">{group.title}</strong>
                            <span className="text-xs text-muted-foreground">
                            <br/>{group.date} {group.time}
                            </span>
                          </div>
                          <ul className="space-y-2 list-disc pl-5">
                            {group.items.map((sentence, idx) => (
                              <li key={idx} className="text-sm">
                                {sentence}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>No tips found in recent summaries.</p>
                  )}
                </CardContent>
              </Card>
            </div>
          </section>

          {/* Detailed Analytics Section */}
          <section className="dashboard-section detailed-analytics">
            <h2 className="section-title">Detailed Analytics</h2>
            {/* 2x3 Grid for Metrics */}
            <div className="grid grid-cols-3 gap-4 metrics-grid">
              {/* Efficiency Metrics */}
              {efficiencyMetrics.map((metric) => (
                <Card isDarkMode={isDarkMode} key={metric.name} className="compact-card">
                  <CardHeader isDarkMode={isDarkMode}>
                    <CardTitle isDarkMode={isDarkMode} className="metric-title flex items-center gap-1">
                      {metric.icon}
                      {metric.name}
                    </CardTitle>
                  </CardHeader>
                  <CardContent isDarkMode={isDarkMode}>
                    <div className="flex flex-col items-start gap-1">
                      <p className="metric-value text-lg font-semibold">
                        {metric.value.toLocaleString()}
                        {metric.suffix || ''}
                      </p>
                      <p className="metric-description text-sm text-muted-foreground">
                        {metric.description}
                      </p>
                      <Progress
                        value={metric.value}
                        className="w-full"
                        max={
                          metric.name === 'Topic Coverage'
                            ? 100
                            : metric.name === 'Processing Rate'
                            ? 1000
                            : metric.value * 1.5
                        }
                      />
                      <p className="text-[10px] mt-2 text-muted-foreground italic">
                        {getMetricDescription(metric.name)}
                      </p>
                    </div>
                  </CardContent>
                </Card>
              ))}

              {/* Engagement Metrics */}
              {[
                {
                  name: 'Engagement Score',
                  value: `${engagementMetrics.engagementScore}%`,
                  progress: engagementMetrics.engagementScore,
                },
                {
                  name: 'Active Days',
                  value: `${engagementMetrics.activeDays} days`,
                  progress: (engagementMetrics.activeDays / 30) * 100,
                },
                {
                  name: 'Daily Frequency',
                  value: `${engagementMetrics.frequency.toFixed(1)} per day`,
                  progress: (engagementMetrics.frequency / 5) * 100,
                },
              ].map((metric, index) => (
                <Card isDarkMode={isDarkMode} key={index} className="compact-card">
                  <CardHeader isDarkMode={isDarkMode}>
                    <CardTitle isDarkMode={isDarkMode} className="metric-title flex items-center gap-1">
                      <TrendingUp className="w-4 h-4" />
                      {metric.name}
                    </CardTitle>
                  </CardHeader>
                  <CardContent isDarkMode={isDarkMode}>
                    <div className="flex flex-col items-start gap-1">
                      <p className="metric-value text-lg font-semibold">{metric.value}</p>
                      <Progress value={metric.progress} className="w-full" />
                      <p className="text-[10px] mt-2 text-muted-foreground italic">
                        {getMetricDescription(metric.name)}
                      </p>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>

            {/* Usage Patterns in its own row */}
            <div className="mt-1 usage-patterns-row">
              <Card isDarkMode={isDarkMode} className="analytics-card">
                <CardHeader isDarkMode={isDarkMode}>
                  <CardTitle isDarkMode={isDarkMode} className="metric-title flex items-center gap-2">
                    <Clock className="w-4 h-4" />
                    Usage Patterns
                  </CardTitle>
                </CardHeader>
                <CardContent isDarkMode={isDarkMode}>
                  <div className="chart-wrapper" style={{ height: '100px' }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart>
                        <Pie
                          data={usagePatterns}
                          cx="50%"
                          cy="35%"
                          innerRadius={50}
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="value"
                          label={({ name, value, percent }) =>
                            `${name}: ${value} (${(percent * 100).toFixed(0)}%)`
                          }
                        >
                          {usagePatterns.map((entry, index) => (
                            <Cell
                              key={entry.name}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <RechartsTooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </CardContent>
              </Card>
            </div>
          </section>

        </div>
        </div>
      )}
    </div>
  );
};

export default IntelligenceDashboard;