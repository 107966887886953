// App.js
import React, { useContext, useEffect, useState } from 'react';
import {
  Link,
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from 'react-router-dom';
import './App.css';
import ContactForm from './components/contactform/ContactForm';
import Navbar from './components/navbar/Navbar';
import { About, Dashboard, Home, Services } from './containers';
import { AuthContext, AuthProvider } from './containers/context/AuthContext';
import Footer from './containers/footer/Footer';
import HomeContent from './containers/home/HomeContent';
import Disclaimer from './containers/home/sections/Disclaimer';
import ExploringSection from './containers/home/sections/ExploringSection';
import PrivacyPolicy from './containers/home/sections/PrivacyPolicy';
import TermsAndConditions from './containers/home/sections/TermsAndConditions';

// Import the IntelligenceDashboard component
import IntelligenceDashboard from './containers/dashboard/Pages/Dashboard/IntelligenceDashboard/IntelligenceDashboard';

const AppFooter = ({ toggleTheme, isDarkMode }) => {
  const location = useLocation();
  const isDashboard =
    location.pathname.startsWith('/dashboard') ||
    location.pathname.startsWith('/intelligence-dashboard');
  return !isDashboard ? <Footer toggleTheme={toggleTheme} isDarkMode={isDarkMode} /> : null;
};

const SimpleNavbar = () => {
  return (
    <nav className="simple-navbar">
      <Link to="/home">Home</Link>
    </nav>
  );
};

const AppContent = () => {
  const [showSplash, setShowSplash] = useState(true);
  const [theme, setTheme] = useState('light');
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [showSignUpPopup, setShowSignUpPopup] = useState(false);

  const toggleSignupPopup = () => {
    setShowSignUpPopup(!showSignUpPopup);
  };

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    setIsDarkMode(!isDarkMode);
    if (newTheme === 'dark') {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  };

  useEffect(() => {
    document.body.classList.remove('dark-mode');
  }, []);

  const location = useLocation();
  const currentPath = location.pathname;
  const hideNavbarPaths = ['/privacy', '/terms', '/disclaimer'];

  const showNavbar = !hideNavbarPaths.includes(currentPath);

  // Scroll to top when accessing specific routes
  useEffect(() => {
    if (hideNavbarPaths.includes(currentPath)) {
      window.scrollTo(0, 0);
    }
  }, [currentPath]);

  return (
    <>
      {showNavbar ? (
        <Navbar
          toggleTheme={toggleTheme}
          isDarkMode={isDarkMode}
          toggleSignupPopup={toggleSignupPopup}
          showSignUpPopup={showSignUpPopup}
        />
      ) : (
        <SimpleNavbar />
      )}
      <Routes>
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route
          path="/about"
          element={<About toggleTheme={toggleTheme} isDarkMode={isDarkMode} />}
        />
        <Route
          path="/services"
          element={<Services toggleTheme={toggleTheme} isDarkMode={isDarkMode} />}
        />
        <Route
          path="/home"
          element={
            <Home
              toggleTheme={toggleTheme}
              isDarkMode={isDarkMode}
              toggleSignupPopup={toggleSignupPopup}
            />
          }
        >
          <Route
            index
            element={
              <HomeContent
                toggleTheme={toggleTheme}
                isDarkMode={isDarkMode}
                toggleSignupPopup={toggleSignupPopup}
              />
            }
          />
          <Route path="products" element={<ExploringSection />} />
          <Route path="contact" element={<ContactForm />} />
        </Route>
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard toggleTheme={toggleTheme} isDarkMode={isDarkMode} />
            </ProtectedRoute>
          }
        />
        {/* Add the Intelligence Dashboard route */}
        <Route
          path="/intelligence-dashboard"
          element={
            <ProtectedRoute>
              <IntelligenceDashboard />
            </ProtectedRoute>
          }
        />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
      </Routes>
      <AppFooter toggleTheme={toggleTheme} isDarkMode={isDarkMode} />
    </>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
};

const ProtectedRoute = ({ children }) => {
  const { user } = useContext(AuthContext);
  if (!user) {
    return <Navigate to="/home" replace />;
  }
  return children;
};

export default App;
