// HelpModal.jsx
import React from 'react';
import HelpContent from './HelpContent'; // Import the new HelpContent component
import './HelpModal.css'; // Import the CSS file here

const HelpModal = ({ onClose }) => {
  // Function to handle clicks on the backdrop
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="helpModal-backdrop" onClick={handleBackdropClick}>
      <div className="helpModal-content" onClick={(e) => e.stopPropagation()}>
        <button className="helpModal-close" onClick={onClose}>Close</button>
        <HelpContent /> {/* Use HelpContent component here */}
      </div>
    </div>
  );
};

export default HelpModal;
