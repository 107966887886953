import React from 'react';

export const Card = ({ className = '', isDarkMode = false, ...props }) => (
  <div
    className={`rounded-lg border shadow-sm transition-colors duration-300 ease-in-out
      ${isDarkMode 
        ? 'bg-gray-800 text-white border-gray-700 hover:shadow-lg hover:border-gray-600' 
        : 'bg-transparent text-gray-900 border-gray-200 hover:shadow-lg hover:border-gray-300'} 
      ${className}`}
    {...props}
  />
);

export const CardHeader = ({ className = '', isDarkMode = false, ...props }) => (
  <div
    className={`flex flex-col space-y-1.5 p-6 transition-colors duration-300 ease-in-out border-b
      ${isDarkMode 
        ? 'bg-gray-900 text-gray-100 border-gray-700' 
        : 'bg-transparent text-gray-800 border-gray-200'} 
      ${className}`}
    {...props}
  />
);

export const CardTitle = ({ className = '', isDarkMode = false, ...props }) => (
  <h3
    className={`text-lg font-semibold leading-none tracking-tight transition-colors duration-300 ease-in-out
      ${isDarkMode 
        ? 'text-white' 
        : 'text-gray-900'} 
      ${className}`}
    {...props}
  />
);

export const CardContent = ({ className = '', isDarkMode = false, ...props }) => (
  <div
    className={`p-6 pt-0 transition-colors duration-300 ease-in-out
      ${isDarkMode 
        ? 'text-gray-200' 
        : 'text-gray-800'}
      ${className}`}
    {...props}
  />
);

export const CardDescription = ({ className = '', isDarkMode = false, ...props }) => (
  <p
    className={`text-sm transition-colors duration-300 ease-in-out
      ${isDarkMode 
        ? 'text-gray-400' 
        : 'text-gray-500'}
      ${className}`}
    {...props}
  />
);

export const CardFooter = ({ className = '', isDarkMode = false, ...props }) => (
  <div
    className={`flex items-center p-6 pt-0 transition-colors duration-300 ease-in-out
      ${isDarkMode 
        ? 'border-t border-gray-700 bg-gray-800' 
        : 'border-t border-gray-200 bg-transparent'}
      ${className}`}
    {...props}
  />
);