import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h1 class="privacy-policy-heading">Terms & Conditions</h1>
    <h2 class="terms-heading">Introduction</h2>
      <p class="terms-content">Welcome to AXN.AI, your go-to solution for media transcription and interactive chat services. By using our website, applications, and services, you agree to adhere to these terms and conditions. Important aspects covered include age restrictions, subscription details, and user conduct. If you're not in agreement with any of these terms, we advise against using our services.</p>

      <h2 class="terms-heading">Service Description</h2>
      <p class="terms-content">AXN.AI ushers in a new era of digital engagement by transforming traditional file interaction into dynamic, conversational experiences. Introducing AXN.ai, your all in one AI-powered solution where you get seamless media transcription, summarization, translation (over 100 languages), and much more. Our services include, but are not limited to, the following features:</p>

      <ul class="terms-feature-list">
          <li class="terms-feature-item">Interactive File Engagement: Users can engage with various digital file formats, including but not limited to, videos, music files, audio files, Word documents, and PDFs, in an interactive manner. This interaction transforms static files into dynamic entities that communicate information in a novel and user-friendly way.</li>
          <li class="terms-feature-item">Data Interpretation and Presentation: AXN.AI facilitates an advanced interpretation of data, enabling users to perceive underlying stories and insights. This feature is designed to enhance user comprehension and engagement with otherwise complex data sets.</li>
          <li class="terms-feature-item">Enhanced Digital Document Interaction: The platform endows digital documents with a level of interactivity that elevates user experience, moving beyond mere simplification to create an enriching and enjoyable interaction with digital content.</li>
          <li class="terms-feature-item">Personalized User Experience: Each interaction with the platform is tailored to provide a unique and engaging experience, with a focus on revealing the intrinsic personality and narrative within each file.</li>
      </ul>

      <p class="terms-content">AXN.AI is committed to continually evolving and expanding its services to deliver innovative digital solutions. Users are encouraged to explore the full potential of our platform, keeping in mind that the scope of services may be updated periodically, as detailed in our updates and communications.</p>

      <h2 class="terms-heading">AXN.ai Products</h2>

      <p class="terms-content">AXN.ai offers a comprehensive suite of AI-powered services designed to enhance productivity, communication, and content management for users across diverse industries and demographics. Our platform leverages advanced artificial intelligence to deliver the following key features and services:</p>

      <ul class="terms-feature-list">
          <li class="terms-feature-item">AI-Enabled Media Recording and Transcription: AXN.ai provides seamless audio and video recording capabilities, along with precise transcription of content in multiple languages, empowering users to capture, document, and analyze critical communication.</li>
          <li class="terms-feature-item">Translation and Summarization Services: With AXN.ai, users can access advanced translation and summarization tools that facilitate the conversion of content across various languages and formats, fostering global communication and content dissemination.</li>
          <li class="terms-feature-item">AI Digital Meeting Assistant - "Alice": Utilizing the power of AI, "Alice" serves as an AI-enabled digital meeting assistant, capable of attending, recording, transcribing, summarizing, and translating meetings in real time, facilitating efficient collaboration and knowledge retention.</li>
          <li class="terms-feature-item">WhatsApp Integrated AI Companion - "Hannah": The innovative "Hannah" feature integrates seamlessly with WhatsApp, offering multi-language communication via text and voice notes. Users can leverage "Hannah" as an AI-powered everyday companion for queries, information, and support.</li>
          <li class="terms-feature-item">Media Upload and Manipulation: AXN.ai's media upload feature supports various file formats, including audio, text, Word, PDF, and Excel. Users can avail themselves of translation, transcription, summarization, and interactive communication with their media transcriptions.</li>
          <li class="terms-feature-item">YouTube Video Transcription and Interaction: Users have the option to upload and process YouTube videos via AXN.ai, enabling transcription, summarization, and translation of content. Additionally, users can engage in interactive Q&A sessions related to specific topics within the video's content.</li>
      </ul>

      <p class="terms-content">AXN.ai is committed to accessibility and inclusivity, ensuring that its services cater to individuals of all backgrounds, professions, and abilities. By integrating cutting-edge AI technologies, AXN.ai strives to revolutionize how users engage with media, communication, and collaboration, transcending linguistic, cultural, and geographical barriers.</p>

      <p class="terms-content">This detailed content outlines the unique features and services offered by AXN.ai, ensuring clarity and transparency for users accessing the platform.</p>

      <h2 class="terms-heading">User Account</h2>

      <h3 class="terms-subheading">Registration and Account Creation</h3>
      <p class="terms-content">Mandatory Registration: To access the full range of AXN.AI's interactive digital experiences, users are required to register and create an account. This process involves providing accurate and current information as prompted during the registration process.</p>
      <p class="terms-content">Account Verification: Depending on the nature of services chosen, additional verification processes may be required to ensure the authenticity and security of user accounts.</p>

      <h3 class="terms-subheading">Age Limitation and Eligibility</h3>
      <p class="terms-content">Minimum Age Requirement: The services of AXN.AI are intended for users who are 13 years of age or older. By creating an account, users affirm that they meet the minimum age requirement.</p>
      <p class="terms-content">Eligibility Verification: AXN.AI reserves the right to request proof of age and to refuse service or terminate accounts if users do not meet the specified age criteria.</p>

      <h3 class="terms-subheading">User Representation & Age Requirements</h3>
      <p class="terms-content">When setting the minimum age limit for users of AXN.ai, it's crucial to consider legal and privacy implications, especially in accordance with relevant regulations such as the Children's Online Privacy Protection Act (COPPA) in the United States.</p>
      <ul class="terms-list">
          <li class="terms-item">Minimum Age Limit: AXN.ai requires users to be at least 13 years of age to access the platform and utilize its services. This aligns with COPPA's age threshold for obtaining verifiable parental consent.</li>
          <li class="terms-item">User Representation: Upon registering or accessing AXN.ai, users affirm that they are at least 13 years old and have obtained any required parental consent if they are under the age of 18.</li>
          <li class="terms-item">Parental Consent: For users under the age of 18, parental consent is required to access certain features or engage in specific interactions on the platform, especially those involving personal data or communication with AI entities like Hannah.</li>
          <li class="terms-item">Data Privacy and Child Protection: AXN.ai follows stringent data privacy and child protection practices to safeguard the online experience of users under the age of 18, ensuring that their interactions comply with legal requirements and ethical standards.</li>
          <li class="terms-item">Legal Acknowledgment: By using AXN.ai, users acknowledge that the platform's minimum age limit and parental consent requirements are in place to comply with relevant laws and protect the privacy and well-being of minors.</li>
          <li class="terms-item">Compliance Assurance: AXN.ai reserves the right to restrict or terminate access to services for users who do not meet the minimum age requirements or violate the platform's age-related policies.</li>
      </ul>

      <h3 class="terms-subheading">Account Responsibility and Security</h3>
      <p class="terms-content">Confidentiality Obligation: Users are solely responsible for maintaining the confidentiality of their account information, including password security. AXN.AI shall not be liable for any loss or damage arising from the user’s failure to comply with this security obligation.</p>
      <p class="terms-content">Notification of Unauthorized Use: Users must promptly notify AXN.AI of any unauthorized use or suspected compromise of their account.</p>

      <h3 class="terms-subheading">User Responsibilities</h3>
      <p class="terms-content">Accuracy of Information: Users are responsible for providing accurate and up-to-date information both at the time of registration and throughout the duration of their use of AXN.AI’s services.</p>
      <p class="terms-content">Legal Compliance: Users must use the service in compliance with all applicable local, state, national, and international laws and regulations.</p>

      <h3 class="terms-subheading">Account Features</h3>
      <p class="terms-content">Interactive Experience Customization: Each user account is designed to adapt and respond to individual user interactions, providing a personalized and engaging experience with digital files.</p>
      <p class="terms-content">Access to Enhanced Features: Registered users gain access to advanced features, including but not limited to interactive data interpretation, personalized content narratives, and other unique functionalities of AXN.AI.</p>

      <h3 class="terms-subheading">Account Termination and Suspension</h3>
      <p class="terms-content">Informed Decision-Making: Users are responsible for making informed decisions when utilizing AXN.ai's AI-powered services. It is crucial for users to evaluate the suitability of the services for their specific needs and to exercise caution when making decisions based on the information provided.</p>
      <p class="terms-content">*Voluntary Account Termination: Users have the right to voluntarily terminate their AXN.ai accounts at any time. Upon request for account termination, all user data and personal information will be securely deleted from our systems, subject to any legal or regulatory requirements for data retention.</p>
      <p class="terms-content">Suspension of Accounts: AXN.ai reserves the right to suspend user accounts in cases where there is a suspected violation of the platform's terms of service, community guidelines, or applicable laws and regulations. Account suspension may result from activities such as misuse of the platform, fraudulent behavior, or engagement in prohibited activities.</p>
      <p class="terms-content">Notification of Suspension: In the event of an account suspension, AXN.ai will provide users with a notification outlining the reason for the suspension, the duration (if temporary), and any necessary steps to address the issue. Users will have the opportunity to appeal the suspension and provide relevant information in their defense.</p>
      <p class="terms-content">Data Preservation during Suspension: During the suspension period, AXN.ai will take appropriate measures to preserve the user's data and information, ensuring its security and integrity. Users will not have access to the platform's services during the suspension period.</p>
      <p class="terms-content">User Initiated termination: Users can terminate their account at any time through the account settings.</p>
      <p class="terms-content">AXN.AI Initiated suspension and/or termination: AXN.AI reserves the right to suspend or terminate accounts at its discretion for any breach of these Terms or for activities harmful to the service or other users.</p>

      <h2 class="terms-heading">Content and Conduct</h2>

      <h3 class="terms-subheading">User-Generated Content</h3>
      <p class="terms-content">Content Ownership and Responsibility: Users retain ownership of the content they generate and are solely responsible for its legality, reliability, and appropriateness. By submitting content to AXN.AI, users grant AXN.AI a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, and display this content in connection with operating and providing the services.</p>
      <p class="terms-content">Compliance with Laws: User-generated content must comply with all applicable local, state, national, and international laws and regulations, including but not limited to copyright and privacy laws.</p>

      <h3 class="terms-subheading">Prohibited Use</h3>
      <p class="terms-content">AXN.ai is committed to maintaining a safe, secure, and inclusive environment for all users. As such, the following activities and use cases are strictly prohibited on the platform:</p>
      <ul class="terms-list">
          <li class="terms-item">Unauthorized Access and Usage: Users are prohibited from engaging in any unauthorized access, usage, or tampering with AXN.ai's services, data, or infrastructure, including attempting to bypass security measures or manipulate content.</li>
          <li class="terms-item">Malicious Behavior: Any form of malicious behavior, including hacking, phishing, malware distribution, or attempts to disrupt the integrity and functionality of AXN.ai's platform, is strictly prohibited.</li>
          <li class="terms-item">Illegal Content and Activities: Users must refrain from uploading, sharing, or engaging in any illegal, fraudulent, or deceptive activities via AXN.ai, including the distribution of prohibited or offensive content.</li>
          <li class="terms-item">Violation of Privacy and Data Protection: AXN.ai strictly prohibits any unauthorized access, collection, or misuse of user data, as well as any other activities that violate privacy laws and regulations.</li>
          <li class="terms-item">Abuse of AI Features: Users must not abuse or misuse AI-enabled features, such as Alice and Hannah, for deceptive, offensive, or malicious purposes, including the dissemination of misinformation or inappropriate communication.</li>
          <li class="terms-item">Excessive & Unauthorized Data Manipulation: Manipulation or alteration of content, data, or transcripts, beyond the scope of authorized usage, is prohibited, ensuring the integrity and accuracy of AXN.ai's services.</li>
          <li class="terms-item">Violation of User Rights: Any activities that infringe upon the rights, privacy, or well-being of AXN.ai users, including harassment, discrimination, or inappropriate use of user-provided content, are strictly prohibited.</li>
          <li class="terms-item">Unlawful Activities: Users are prohibited from using AXN.AI services for any unlawful purposes, including but not limited to, infringement of intellectual property rights, fraud, and activities that violate privacy or publicity rights.</li>
          <li class="terms-item">Disruptive Conduct: Engaging in conduct that interrupts, damages, or impairs the functionality of the service, including the dissemination of viruses, malicious code, or other harmful technology, is strictly prohibited.</li>
          <li class="terms-item">Harassment and Abuse: Users must not engage in behavior that is harassing, threatening, abusive, or otherwise harmful to other users or to AXN.AI staff.</li>
      </ul>
      <p class="terms-content">Users engaging in any prohibited use as outlined above are subject to immediate account suspension or termination, as well as potential legal action and reporting to relevant authorities as deemed necessary. It is imperative for all users to adhere to these prohibitions to ensure the ethical, safe, and productive use of AXN.ai's services for the benefit of the entire user community.</p>

      <h3 class="terms-subheading">Enforcement and Consequences</h3>
      <p class="terms-content">Monitoring and Enforcement: AXN.AI reserves the right, but is not obligated, to monitor content and conduct on its platform. AXN.AI may take appropriate action, including removal of content and account suspension or termination, in response to violations of these terms.</p>
      <p class="terms-content">Reporting Violations: Users are encouraged to report any violations of these terms to AXN.AI for prompt action.</p>

      <h3 class="terms-subheading">Content Interaction Features</h3>
      <p class="terms-content">Innovative Engagement: In line with AXN.AI’s commitment to transforming digital interactions, users may experience enhanced and interactive ways of engaging with their own and others' content, subject to the terms outlined herein.</p>
      <p class="terms-content">Feedback and Improvements: User feedback on these interactive features is welcomed and may be used to improve the services offered by AXN.AI.</p>

      <h3 class="terms-subheading">Intellectual Property</h3>
      <p class="terms-content">Ownership of Service Material</p>
      <p class="terms-content">Ownership and Licensing: All content, features, and services provided by AXN.ai are protected by intellectual property laws, including but not limited to copyright, trademark, and trade secret laws. AXN.ai retains ownership of all proprietary technology, algorithms, software, and content used in its services. - Users are granted a limited, non-exclusive, non-transferable license to access and use AXN.ai's services for personal or internal business purposes, subject to compliance with the terms and conditions outlined in this agreement.</p>
      <p class="terms-content">User-Generated Content: Users are prohibited from uploading, sharing, or modifying content that infringes upon the intellectual property rights of AXN.ai, its users, or any third party. This includes copyrighted material, trademarks, trade secrets, and proprietary information.</p>
      <p class="terms-content">End-User Compliance: By accessing and utilizing AXN.ai's services, users agree to respect intellectual property rights, refrain from unauthorized use of content, and comply with all applicable laws and regulations pertaining to intellectual property.</p>
      <p class="terms-content">Intellectual Property Infringement Reporting: AXN.ai respects the intellectual property rights of others and encourages users to report any suspected infringement of their rights. Any notices of alleged infringement should be submitted in accordance with the procedures outlined in the terms of service.</p>
      <p class="terms-content">Limitation of Use and Reproduction: Users are prohibited from reproducing, modifying, distributing, or creating derivative works from AXN.ai's proprietary technology, algorithms, software, and content without explicit consent from AXN.ai.</p>
      <p class="terms-content">Reservation of Rights: AXN.ai reserves the right to take appropriate legal action in response to any unauthorized use, reproduction, distribution, or modification of its intellectual property, as well as the right to terminate access to services for users who violate intellectual property rights.</p>
      <p class="terms-content">Proprietary Rights: All materials related to the services provided by AXN.AI, including but not limited to software, text, images, graphics, logos, and trademarks, are the exclusive property of AXN.AI or its licensors. These materials are protected under intellectual property laws both domestically and internationally.</p>
      <p class="terms-content">No Transfer of Rights: Usage of AXN.ai services does not constitute a transfer or granting of any ownership rights in the service materials to users.</p>

      <h2 class="terms-heading">Use of Service Materials</h2>

      <h3 class="terms-subheading">Permissible Use</h3>
      <p class="terms-content">Users are granted a limited, revocable, non-exclusive, non-transferable license to access and utilize the services and materials provided by AXN.ai for personal or internal business purposes.</p>
      <p class="terms-content">Content Ownership and Restrictions: All proprietary technology, algorithms, and software used in the services, including AI-enabled tools such as Alice and Hannah, are the exclusive property of AXN.ai and its licensors. Users must not reproduce, modify, distribute, or create derivative works from the service materials without explicit consent.</p>
      <p class="terms-content">User-Generated Content: By uploading or providing content to AXN.ai, users retain ownership of their respective intellectual property rights. However, users grant AXN.ai a worldwide, royalty-free, non-exclusive license to use, distribute, and modify the provided content for the purpose of delivering the requested services.</p>
      <p class="terms-content">Interactive Communication: Users engaging in interactive communication with AI entities such as Hannah through voice notes, text, or uploaded media acknowledge that the provided responses and interactions are generated by AI technology and may not always reflect human judgment or emotion.</p>
      <p class="terms-content">Data Privacy and Confidentiality: AXN.ai respects the privacy and confidentiality of user-generated content and ensures that all interactions adhere to strict data protection protocols, safeguarding the proprietary nature of provided materials.</p>
      <p class="terms-content">Intellectual Property Reporting: Users are encouraged to report any suspected infringement of their intellectual property rights or unauthorized use of their materials by other users, enabling AXN.ai to take appropriate action to address the reported concerns.</p>
      <p class="terms-content">Compliance Assurance: Users must comply with all applicable laws and regulations pertaining to the use, transmission, and storage of service materials, including but not limited to intellectual property rights, data privacy, and confidentiality.</p>
      <p class="terms-content">Restricted Use: Users are permitted to use the service materials solely for personal and non-commercial purposes in connection with the services provided by AXN.AI. Any other use, including reproduction, modification, distribution, transmission, or display of service materials, is strictly prohibited without the express written consent of AXN.AI.</p>
      <p class="terms-content">Intellectual Property Compliance: Users must respect the intellectual property rights of AXN.AI and third parties at all times. Unauthorized use of service materials may result in civil and criminal penalties.</p>

      <h3 class="terms-subheading">User Content and Intellectual Property</h3>
      <p class="terms-content">Ownership of User-Generated Content: Users retain full ownership and responsibility for any content, media, or materials they upload, transmit, or generate while utilizing AXN.ai's services, including but not limited to audio files, transcripts, and media uploads.</p>
      <p class="terms-content">License Grant to AXN.ai: By uploading or providing content to AXN.ai, users grant the platform a non-exclusive, worldwide, royalty-free license to use, distribute, modify, and process the user-generated content for the purpose of delivering the requested services. This license facilitates the functionalities of media transcription, translation, and interactive communication, ensuring the effective delivery of AXN.ai's AI-enabled tools such as Alice and Hannah.</p>
      <p class="terms-content">Respect for Intellectual Property Rights: Users are required to respect the intellectual property rights of others, refrain from uploading, sharing, or modifying content that infringes upon copyright, trademark, or proprietary rights, and comply with all applicable laws and regulations.</p>
      <p class="terms-content">Data Privacy and Confidentiality: AXN.ai respects the privacy and confidentiality of user-generated content, implementing robust data protection and security measures to safeguard the materials and interactions between users and the platform's AI-enabled features.</p>
      <p class="terms-content">Intellectual Property Reporting and Compliance: AXN.ai encourages users to report any suspected infringement of their intellectual property rights, unauthorized use of their content, or violations related to intellectual property and content management. The platform is committed to addressing reported concerns and ensuring compliance with relevant laws and regulations.</p>
      <p class="terms-content">Limitation of Use and Reproduction: Users are prohibited from reproducing, modifying, distributing, or creating derivative works from AXN.ai's proprietary technology, algorithms, software, and content without explicit consent, ensuring the protection and integrity of the platform's intellectual property.</p>
      <p class="terms-content">User Content Rights: Users retain all rights to the intellectual property they own and submit to the AXN.AI platform. However, by submitting content, users grant AXN.AI a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, and display the content in relation to the provided services.</p>
      <p class="terms-content">Respect for Third-Party Rights: Users must ensure that any content they submit does not infringe upon the intellectual property rights of third parties.</p>

      <h2 class="terms-heading">Intellectual Property Inquiries and Violations</h2>

      <h3 class="terms-subheading">Reporting Infringements</h3>
      <p class="terms-content">Users are encouraged to report any suspected infringements of intellectual property rights to AXN.AI for appropriate action.</p>

      <h3 class="terms-subheading">Response to Infringements</h3>
      <p class="terms-content">AXN.AI reserves the right to remove any content that is alleged to be infringing and may terminate the accounts of repeat infringers in accordance with applicable law.</p>

      <h3 class="terms-subheading">Respect for Intellectual Property Rights</h3>
      <p class="terms-content">AXN.ai is dedicated to upholding the intellectual property rights of all individuals and entities, including copyrights, trademarks, patents, and proprietary information. Users are expected to adhere to all relevant intellectual property laws and regulations when using our services.</p>

      <h3 class="terms-subheading">Inquiry Process for Intellectual Property Concerns</h3>
      <p class="terms-content">In circumstances where inquiries, concerns, or notices regarding potential intellectual property violations arise, individuals or entities asserting rights are encouraged to contact AXN.ai's designated intellectual property representative. Contact details for the representative will be made available upon request.</p>

      <h3 class="terms-subheading">Prompt Review and Resolution</h3>
      <p class="terms-content">Upon receipt of a legitimate inquiry or notice regarding intellectual property violations, AXN.ai will swiftly review the claim and take necessary action, which may involve removing or limiting access to the purportedly infringing material within a reasonable timeframe.</p>

      <h3 class="terms-subheading">User Cooperation and Disclosure</h3>
      <p class="terms-content">Users subject to intellectual property inquiries or claims are expected to collaborate with AXN.ai by providing pertinent information, evidence, or justifications to address the concerns. Users may be required to disclose the source, ownership, or rights related to the content in question.</p>

      <h2 class="terms-heading">Contact Information</h2>
      <p class="terms-content">For any questions about these terms, please contact us at support@AXN.ai</p>

      <h2 class="terms-heading">Effective Date</h2>
      <p class="terms-content">These terms are effective as of 8 February 2024.</p>
    </div>
  );
};

export default TermsAndConditions;