import { Expo, gsap, Quint } from 'gsap';
import $ from 'jquery';
import React, { useEffect, useRef } from 'react';
import TwoStepsLrCard from '../../../components/cards/TwoStepsLrCard';
import TwoStepsRlCard from '../../../components/cards/TwoStepsRlCard';
import useInView from '../../../components/inView/useInView';
import Testimonials from './Testimonials';

const TwoStepsSection = () => {
    const [setRef, inView] = useInView({
        threshold: 0.1, // Trigger when the section is at least 10% in view
    });
    const contentContainerRef = useRef(null);
    const carouselContainerRef = useRef(null);
    let w, container, carousel, item, radius, itemLength, rY, ticker, fps;
    let mouseX = 0;
    let mouseY = 0;
    let mouseZ = 0;
    let addX = 0;

    const counterRef = useRef({ // Using useRef to persist the counter object
        tick: function () {
            this.times = this.times.concat(+new Date());
            var seconds, times = this.times;

            if (times.length > this.span + 1) {
                times.shift(); // ditch the oldest time
                seconds = (times[times.length - 1] - times[0]) / 1000;
                return Math.round(this.span / seconds);
            } else return null;
        },
        times: [],
        span: 20
    });


    useEffect(() => {
        const init = () => {
            w = $(window);
            container = $('#contentContainer');
            carousel = $('#carouselContainer');
            item = $('.carouselItem');
            itemLength = $('.carouselItem').length;
            fps = $('#fps');
            rY = 360 / itemLength;
    
            // Dynamically adjust radius based on screen size
            // Example: 20% of the viewport width or height, whichever is smaller
            const viewportWidth = w.width();
            const viewportHeight = w.height();
            const baseRadiusPercentage = 0.2; // Adjust this value to control the size
            const baseSize = Math.min(viewportWidth, viewportHeight) * baseRadiusPercentage;
            radius = Math.round(baseSize / Math.tan(Math.PI / itemLength));
    
            // Set container 3D properties
            gsap.set(container, { perspective: 300 });
            gsap.set(carousel, { z: -(radius) });
    
            // Create carousel item properties
            for (let i = 0; i < itemLength; i++) {
                const $item = item.eq(i);
                const $block = $item.find('.carouselItemInner');
    
                // Set initial rotation and position
                gsap.set($item, { rotationY: rY * i, z: radius, transformOrigin: "50% 50% " + -radius + "px" });
    
                // Animate the carousel item in
                animateIn($item, $block);
            }
    
            // Start automatic rotation
            ticker = setInterval(() => looper(fps), 1000 / 60);
        };
    
        const counter = fps_counter(); // Initialize the counter
        init();
    
        // Clean up function
        return () => {
            clearInterval(ticker);
        };
    }, []);
    
    
    function looper(fps) {
        addX += 0.2; // Adjust rotation speed as desired
        gsap.to(carousel, { duration: 1, rotationY: addX, ease: Quint.easeOut });
        fps.text('Framerate: ' + counterRef.current.tick() + '/60 FPS');
    }
    

    function animateIn($item, $block) {
        const $nrX = 360 * getRandomInt(2);
        const $nrY = 360 * getRandomInt(2);
        
        const $nx = -(2000) + getRandomInt(4000);
        const $ny = -(2000) + getRandomInt(4000);
        const $nz = -4000 + getRandomInt(4000);
        
        const $s = 1.5 + (getRandomInt(10) * .1);
        const $d = 1 - (getRandomInt(8) * .1);
    
        // Ensure the width and height of the $block remain consistent
        const blockWidth = $block.width();
        const blockHeight = $block.height();
    
        gsap.set($item, { autoAlpha: 1, delay: $d });
        gsap.set($block, { z: $nz, rotationY: $nrY, rotationX: $nrX, x: $nx, y: $ny, autoAlpha: 0, width: blockWidth, height: blockHeight });
        gsap.to($block, { duration: $s, delay: $d, rotationY: 0, rotationX: 0, z: 0, ease: Expo.easeInOut });
        gsap.to($block, { duration: $s - 0.5, delay: $d, x: 0, y: 0, autoAlpha: 1, ease: Expo.easeInOut });
    }
    function getRandomInt($n) {
        return Math.floor((Math.random() * $n) + 1);
    }

    // Define the fps_counter function outside of the useEffect hook
    function fps_counter() {
        return {
            tick: function () {
                this.times = this.times.concat(+new Date());
                var seconds, times = this.times;

                if (times.length > this.span + 1) {
                    times.shift(); // ditch the oldest time
                    seconds = (times[times.length - 1] - times[0]) / 1000;
                    return Math.round(this.span / seconds);
                } else return null;
            },
            times: [],
            span: 20
        };
    }
    return (
        <section className={`cards-section ${inView ? 'in-view' : ''}`} ref={setRef}>
            <div className="header text-center pb-3">
                <h1 className='mb-4'>Join the AI Revolution - Efficiency and Intelligence
                    <br /> at Your Command.</h1>
                    <div id="contentContainer" className="trans3d">
                        <section id="carouselContainer" className="trans3d">
                            <figure id="item1" className="carouselItem trans3d">
                                <div className="carouselItemInner trans3d">
                                <img src="assets/icons/caro/image1.png" alt="Carousel Image 1" />
                                </div>
                            </figure>
                            <figure id="item2" className="carouselItem trans3d">
                                <div className="carouselItemInner trans3d">
                                <img src="assets/icons/caro/image2.png" alt="Carousel Image 2" />
                                </div>
                            </figure>
                            <figure id="item3" className="carouselItem trans3d">
                                <div className="carouselItemInner trans3d">
                                <img src="assets/icons/caro/image3.png" alt="Carousel Image 3" />
                                </div>
                            </figure>
                            {/* Add the remaining carousel items here */}
                            <figure id="item4" className="carouselItem trans3d">
                                <div className="carouselItemInner trans3d">
                                <img src="assets/icons/caro/image4.png" alt="Carousel Image 4" />
                                </div>
                            </figure>
                            <figure id="item5" className="carouselItem trans3d">
                                <div className="carouselItemInner trans3d">
                                <img src="assets/icons/caro/image5.png" alt="Carousel Image 5" />
                                </div>
                            </figure>
                            <figure id="item6" className="carouselItem trans3d">
                                <div className="carouselItemInner trans3d">
                                <img src="assets/icons/caro/image6.png" alt="Carousel Image 6" />
                                </div>
                            </figure>
                            <figure id="item7" className="carouselItem trans3d">
                                <div className="carouselItemInner trans3d">
                                <img src="assets/icons/caro/image7.png" alt="Carousel Image 7" />
                                </div>
                            </figure>
                            <figure id="item8" className="carouselItem trans3d">
                                <div className="carouselItemInner trans3d">
                                <img src="assets/icons/caro/image8.png" alt="Carousel Image 8" />
                                </div>
                            </figure>
                            <figure id="item9" className="carouselItem trans3d">
                                <div className="carouselItemInner trans3d">
                                <img src="assets/icons/caro/image9.png" alt="Carousel Image 9" />
                                </div>
                            </figure>
                            <figure id="item10" className="carouselItem trans3d">
                                <div className="carouselItemInner trans3d">
                                <img src="assets/icons/caro/image10.png" alt="Carousel Image 10" />
                                </div>
                            </figure>
                            <figure id="item11" className="carouselItem trans3d">
                                <div className="carouselItemInner trans3d">
                                <img src="assets/icons/caro/image11.png" alt="Carousel Image 11" />
                                </div>
                            </figure>
                            <figure id="item12" className="carouselItem trans3d">
                                <div className="carouselItemInner trans3d">
                                <img src="assets/icons/caro/image12.png" alt="Carousel Image 12" />
                                </div>
                            </figure>
                            <figure id="item13" className="carouselItem trans3d">
                                <div className="carouselItemInner trans3d">
                                <img src="assets/icons/caro/image13.png" alt="Carousel Image 13" />
                                </div>
                            </figure>
                            <figure id="item14" className="carouselItem trans3d">
                                <div className="carouselItemInner trans3d">
                                <img src="assets/icons/caro/image14.png" alt="Carousel Image 14" />
                                </div>
                            </figure>
                            <figure id="item15" className="carouselItem trans3d">
                                <div className="carouselItemInner trans3d">
                                <img src="assets/icons/caro/image15.png" alt="Carousel Image 15" />
                                </div>
                            </figure>
                            <figure id="item16" className="carouselItem trans3d">
                                <div className="carouselItemInner trans3d">
                                <img src="assets/icons/caro/image16.png" alt="Carousel Image 16" />
                                </div>
                            </figure>
                            <figure id="item17" className="carouselItem trans3d">
                                <div className="carouselItemInner trans3d">
                                <img src="assets/icons/caro/image17.png" alt="Carousel Image 17" />
                                </div>
                            </figure>
                            <figure id="item18" className="carouselItem trans3d">
                                <div className="carouselItemInner trans3d">
                                <img src="assets/icons/caro/image18.png" alt="Carousel Image 18" />
                                </div>
                            </figure>
                            <figure id="item19" className="carouselItem trans3d">
                                <div className="carouselItemInner trans3d">
                                <img src="assets/icons/caro/image19.png" alt="Carousel Image 19" />
                                </div>
                            </figure>
                            <figure id="item20" className="carouselItem trans3d">
                                <div className="carouselItemInner trans3d">
                                <img src="assets/icons/caro/image20.png" alt="Carousel Image 20" />
                                </div>
                            </figure>
                            
                        </section>
                        <p className="">Around the globe, pros from every field are utilizing AXN.ai. Even the big names at 
                            <br />Fortune 500 companies turn to AXN.ai to save precious time and get more done. </p>
                    </div>
                
            </div>
            <div className="body t-scards-list my-5" ref={contentContainerRef}>


                <TwoStepsLrCard
                    heading="Education"
                    desc="AXN.ai- Shaping Future Minds with Ease. Teachers and students alike are raising their hands for AXN. ai. Homework? It's a breeze when summaries and translations are just a click away. Studying's never been this smooth, and with Alice and Hannah, everyone's acing the test."
                    vid="assets/video/edu1.mp4"
                    
                />
                <TwoStepsRlCard
                    heading="Engineering & IT"
                    desc="Engineers streamline their workflows, from power grids to software updates. Automotive pros tune up reports in record time, Telecom technicians connect more dots, and electronics wizards can finally catch their breath"
                    vid="assets/video/eng1.mp4"
                />
                <TwoStepsLrCard
                    heading="Health Care"
                    desc="AXN.ai-Healing with a Tech Touch: Doctors and nurses talk to Hannah and get instant answers & translations, making sure nothing's lost in translation. Patient records? Summarized and streamlined in a flash. AXN. ai's giving healthcare workers more time to care."
                    vid="assets/video/doc1.mp4"
                />
                <TwoStepsRlCard
                    heading="Law & Justice"
                    desc="AXN.ai-Justice, Served Up Quicker. Lawyers are keeping cases on the fast track. Mountains of paperwork? Alice nibbles them down to bite-size notes. Now, legal eagles spend less time at desks and more time in court, winning. "
                    vid="assets/video/law1.mp4"
                />

            </div>
            <Testimonials />

        </section>
        
    );
}

export default TwoStepsSection;
