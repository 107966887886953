// src/containers/context/AuthContext.jsx
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React, { createContext, useContext, useEffect, useState } from 'react';
import firebaseConfig from '../../firebaseConfig';

const firebaseApp = initializeApp(firebaseConfig);

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isFullyAuthenticated, setIsFullyAuthenticated] = useState(false);
  const auth = getAuth(firebaseApp);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      // Only set as fully authenticated if user exists AND email is verified
      // OR if it's a Google sign-in (which is pre-verified)
      if (currentUser) {
        const isGoogleUser = currentUser.providerData.some(
          (provider) => provider.providerId === 'google.com'
        );
        setIsFullyAuthenticated(currentUser.emailVerified || isGoogleUser);
      } else {
        setIsFullyAuthenticated(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ 
      user, 
      isAuthenticated: !!user,
      isFullyAuthenticated 
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);