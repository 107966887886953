import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <h1 class="privacy-policy-heading">Privacy Policy</h1>
        <p class="privacy-policy-last-updated"><strong>Last updated:</strong> April 1, 2024</p>
        <p class="privacy-policy-introduction">At AXN.ai, based in Delaware, U.S., we respect the privacy of our users and are committed to protecting the personal information you may provide while interacting with our services, including our AI-enabled transcription and summarization platform, and our AI-assistant services via WhatsApp; Hanah. This Privacy Policy outlines how we collect, use, share your information, and the measures we take to ensure its protection.</p>

        <h2 class="section-heading">User Rights and Choices</h2>

        <h3 class="sub-section-heading">Data Access and Control:</h3>
        <p class="sub-section-content">Users have the right to access and control their personal data and interactions within AXN.ai's platform. This includes the ability to view, edit, or delete personal information, communication records, and media content uploaded to the platform.</p>

        <h3 class="sub-section-heading">Consent and Expressive Choices:</h3>
        <p class="sub-section-content">AXN.ai respects user autonomy and provides transparency in obtaining informed consent for data collection and use. Users are empowered to make expressive choices regarding the extent of data sharing, disclosure, and preferences for personalized service delivery.</p>

        <h3 class="sub-section-heading">Privacy Settings and Management:</h3>
        <p class="sub-section-content">The platform offers user-friendly privacy settings and management options, enabling users to customize data sharing and access permissions, control communication preferences with AI tools, and manage data retention and deletion.</p>

        <h3 class="sub-section-heading">Opt-Out and Unsubscribe Options:</h3>
        <p class="sub-section-content">Users have the freedom to opt out of specific data collection or communication features within AXN.ai's services. The platform respects user choices to unsubscribe from certain communications and data interactions, maintaining privacy and empowering user control.</p>

        <h3 class="sub-section-heading">Ethical Data Usage and Transparency:</h3>
        <p class="sub-section-content">AXN.ai operates with transparency and ethical data usage practices, honoring user choices and providing clear communication about the intended use of user data, interactions with AI tools, and personalized content delivery.</p>

        <h3 class="sub-section-heading">Compliance with User Preferences:</h3>
        <p class="sub-section-content">The platform is committed to respecting user preferences and choices, ensuring that data sharing, disclosure, and interactions align with individual consent and desires for personalized service delivery.</p>

        <h3 class="sub-section-heading">Legal Compliance and Accountability:</h3>
        <p class="sub-section-content">All user rights and choices within AXN.ai's services fully comply with applicable data protection laws and regulations, with a commitment to ethical and legal standards. The platform stands accountable for upholding user privacy rights and data protection requirements.</p>

        <h3 class="sub-section-heading">Acknowledgment and Consent:</h3>
        <p class="sub-section-content">By using AXN.ai's services, users acknowledge and accept the user rights and choices provisions outlined in the privacy policy. Users agree to release AXN.ai from any and all obligations, liabilities, violations, or lawsuits related to user rights and choices to the fullest extent permitted by law.</p>

        <p class="privacy-policy-summary">This comprehensive write-up outlines the user rights and choices within AXN.ai's platform, providing legal protection, user empowerment, and ethical standards for data sharing, disclosure, and user interactions.</p>

        <h2 class="section-heading">Data Security and Retention</h2>

        <h3 class="sub-section-heading">Comprehensive Data Security Measures:</h3>
        <p class="sub-section-content">AXN.ai is dedicated to upholding the highest standards of data security, employing robust encryption, access controls, and secure storage protocols to safeguard user data from unauthorized access, breaches, and cyber threats.</p>

        <h3 class="sub-section-heading">Confidentiality and Integrity:</h3>
        <p class="sub-section-content">User data, including personal details, communication records, and media content, is treated with the utmost confidentiality and integrity, ensuring its protection and preventing any unauthorized modifications or access.</p>

        <h3 class="sub-section-heading">Minimized Data Retention:</h3>
        <p class="sub-section-content">AXN.ai adheres to stringent data retention policies, ensuring that user data is retained only for the necessary duration to fulfill the purposes for which it was collected. Once the data is no longer required, it is securely and permanently deleted in accordance with legal and regulatory requirements.</p>

        <h3 class="sub-section-heading">Limitations on Access:</h3>
        <p class="sub-section-content">Access to user data is restricted to authorized personnel on a need-to-know basis, with rigorous access controls and monitoring mechanisms in place to prevent unauthorized use, disclosure, or modification of user information.</p>

        <h3 class="sub-section-heading">Data Breach Response and Notification:</h3>
        <p class="sub-section-content">In the event of a data breach, AXN.ai is committed to promptly assessing, mitigating, and resolving the breach, and if necessary, providing timely and transparent notification to affected users, authorities, and relevant stakeholders.</p>

        <h3 class="sub-section-heading">Legal Compliance and Accountability:</h3>
        <p class="sub-section-content">All data security and retention practices within AXN.ai fully comply with applicable data protection laws and regulations, with a commitment to ethical and legal standards. The platform stands accountable for upholding user privacy rights and data protection requirements.</p>

        <h3 class="sub-section-heading">User Access and Control:</h3>
        <p class="sub-section-content">Users are empowered to access, review, and request the deletion of their personal data, maintaining control over their information and ensuring compliance with their privacy preferences and rights.</p>

        <h3 class="sub-section-heading">Acknowledgment and Consent:</h3>
        <p class="sub-section-content">By utilizing AXN.ai's services, users acknowledge and accept the data security and retention provisions outlined in the privacy policy. Users agree to release AXN.ai from any and all obligations, liabilities, violations, or lawsuits related to data security and retention to the fullest extent permitted by law.</p>

        <h3 class="sub-section-heading">Security Measures:</h3>
        <p class="sub-section-content">AXN.AI employs robust security measures to protect personal information from unauthorized access, alteration, disclosure, or destruction.</p>

        <h3 class="sub-section-heading">Retention Policy:</h3>
        <p class="sub-section-content">Personal information is retained only for as long as necessary to fulfill the purposes for which it was collected, in compliance with our data retention policies and applicable laws.</p>

        <h2 class="section-heading">Policy Updates and Notifications</h2>

        <h3 class="sub-section-heading">Amendments:</h3>
        <p class="sub-section-content">This Privacy Policy may be updated periodically to reflect changes in our practices or regulatory requirements. Users will be notified of significant changes through our services or via email.</p>

        <h3 class="sub-section-heading">Effective Date and Acknowledgement:</h3>
        <p class="sub-section-content">The effective date of the policy will be clearly stated, and continued use of the service after any changes will constitute acceptance of the new terms.</p>

        <h2 class="section-heading">Payment and Subscription</h2>

        <h3 class="sub-section-heading">Fees and Payment Terms</h3>

        <h4 class="sub-sub-section-heading">Service Fees:</h4>
        <p class="sub-sub-section-content">Certain features of AXN.AI’s services may require payment of fees. The specific fees and payment terms, including any applicable taxes, will be detailed at the time of purchase or subscription.</p>

        <h4 class="sub-sub-section-heading">Payment Processing:</h4>
        <p class="sub-sub-section-content">Payments are processed through secure, third-party payment gateways. Users are responsible for providing accurate and valid payment information.</p>

        <h3 class="sub-section-heading">Subscription Services</h3>

        <h4 class="sub-sub-section-heading">Subscription Agreement:</h4>
        <p class="sub-sub-section-content">By subscribing to AXN.AI services, users agree to pay the recurring subscription fees as specified during the subscription process.</p>

        <h4 class="sub-sub-section-heading">Renewal and Billing:</h4>
        <p class="sub-sub-section-content">Subscriptions are automatically renewed at the end of each billing cycle, unless cancelled by the user. Users will be billed in accordance with the billing terms disclosed at the time of subscription.</p>

        <h2 class="section-heading">Cancellation and Refunds</h2>

        <p class="section-summary">This detailed section addresses the cancellation and refund policy for outstanding credits within AXN.ai's services, aiming to provide clarity, fairness, and legal compliance while protecting the company from potential obligations, liabilities, and disputes. Always seek legal advice to review and finalize the terms for legal compliance.</p>

        <h3 class="sub-section-heading">Cancellation Policy:</h3>
        <p class="sub-section-content">Users may cancel their subscription at any time. The cancellation will take effect at the end of the current billing cycle.</p>

        <h3 class="sub-section-heading">Refund for Outstanding Credits:</h3>
        <p class="sub-section-content">AXN.ai generally does not provide refunds for outstanding credits available to users. Credits are non-transferable and non-refundable within the platform's service offerings.</p>

        <h3 class="sub-section-heading">Exceptional Circumstances:</h3>
        <p class="sub-section-content">In exceptional circumstances, AXN.ai may consider refund requests for outstanding credits. Users are encouraged to reach out to customer support with compelling reasons for the refund request.</p>

        <h3 class="sub-section-heading">Transparency in Refund Review:</h3>
        <p class="sub-section-content">Refund requests for outstanding credits will be reviewed with transparency and fairness, considering the individual circumstances and policy limitations.</p>

        <h3 class="sub-section-heading">User Responsibility:</h3>
        <p class="sub-section-content">Users are responsible for managing their credits and are encouraged to utilize them effectively within the platform's services. Utilization of credits does not guarantee eligibility for a refund.</p>

        <h3 class="sub-section-heading">Discontinuation of Services:</h3>
        <p class="sub-section-content">In the event of service discontinuation or suspension, AXN.ai will provide information regarding any applicable refunds or compensation owed to users.</p>

        <h3 class="sub-section-heading">Legal Compliance and Accountability:</h3>
        <p class="sub-section-content">The cancellation and refund policy complies with all applicable laws and regulations concerning user rights and reimbursement privileges.</p>

        <h3 class="sub-section-heading">Exceptional Circumstances Review:</h3>
        <p class="sub-section-content">In instances where exceptional circumstances warrant a refund, AXN.ai commits to conducting a fair and thorough review process to address user concerns.</p>

        <h3 class="sub-section-heading">Effective Communication:</h3>
        <p class="sub-section-content">Users are encouraged to communicate with AXN.ai's customer support for any cancellation or refund-related queries, ensuring clear and effective dialogue.</p>

        <h2 class="section-heading">Changes to Fees and Services</h2>

        <h3 class="sub-section-heading">Modification Rights:</h3>
        <p class="sub-section-content">AXN.AI reserves the right to modify the subscription fees and the services offered at any time. Such changes will be communicated to users in advance.</p>

        <h3 class="sub-section-heading">Acceptance of Changes:</h3>
        <p class="sub-section-content">Continued use of AXN.AI’s services after any changes to fees or services constitutes acceptance of the new terms.</p>

        <h2 class="section-heading">Termination</h2>

        <h3 class="sub-section-heading">Termination by AXN.AI</h3>

        <h4 class="sub-sub-section-heading">Rights of Termination:</h4>
        <p class="sub-sub-section-content">AXN.AI reserves the right to terminate or suspend a user's access to the services immediately, without prior notice, in the event of a breach of these Terms, or if the user engages in conduct harmful to AXN.AI, its services, or other users.</p>

        <h4 class="sub-sub-section-heading">Consequences of Termination:</h4>
        <p class="sub-sub-section-content">Upon termination, the user's right to access and use the services will cease immediately. AXN.AI is not liable for any damages or loss resulting from termination.</p>

        <h3 class="sub-section-heading">Termination by User</h3>

        <h4 class="sub-sub-section-heading">User Initiated Termination:</h4>
        <p class="sub-sub-section-content">Users may terminate their use of AXN.AI’s services at any time by closing their account through the account settings. Such termination will take effect immediately.</p>

        <h4 class="sub-sub-section-heading">Effect of Account Closure:</h4>
        <p class="sub-sub-section-content">Upon closure of an account, the user's access to the services will be terminated, and any remaining credits or subscriptions will be forfeited.</p>

        <h3 class="sub-section-heading">Survival of Terms:</h3>
        <p class="sub-section-content">Sections concerning rights, responsibilities, limitations of liability, and dispute resolution will survive termination and continue to apply even after termination.</p>

        <h2 class="section-heading">Governing Law and Dispute Resolution</h2>

        <h3 class="sub-section-heading">Governing Law:</h3>
        <p class="sub-section-content">These Terms shall be governed by and construed in accordance with the laws of the State of Delaware, United States, without regard to its conflict of law provisions.</p>

        <h3 class="sub-section-heading">Dispute Resolution:</h3>
        <p class="sub-section-content">Any dispute arising out of or relating to these Terms or the services provided by AXN.AI shall be resolved through binding arbitration conducted by the American Arbitration Association (AAA) in accordance with its Commercial Arbitration Rules.</p>

        <h3 class="sub-section-heading">Arbitration Process:</h3>
        <p class="sub-section-content">The arbitration shall take place in Wilmington, Delaware, and the arbitrator's decision shall be final and binding. Each party shall bear its own costs and expenses, including attorney's fees, associated with the arbitration.</p>

        <h3 class="sub-section-heading">Class Action Waiver:</h3>
        <p class="sub-section-content">Both parties waive the right to bring any dispute as a class, consolidated, representative, collective, or private attorney general action, or to participate in a class, consolidated, representative, collective, or private attorney general action regarding any dispute.</p>

        <h2 class="section-heading">Contact Us</h2>

        <p class="sub-section-content">If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at support@axn.ai.</p>

    </div>
  );
};

export default PrivacyPolicy;