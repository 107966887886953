import React from 'react';

const Disclaimer = () => {
  return (
    <div className="disclaimer">
      <h1 class="privacy-policy-heading">Disclaimer</h1>
        <h2 class="disclaimer-heading">Disclaimer</h2>

        <p class="disclaimer-content">AXN.ai, a subsidiary of Kiwix LLC registered in Delaware, US, operates as an AI powered software website offering a range of AI enabled services, including media recording, transcription, translation, and summarization. The platform encompasses AI tools such as Alice, a digital meeting assistant, and Hannah, integrated on WhatsApp, offering diverse communication and media interaction capabilities to users across various demographics.</p>

        <h3 class="disclaimer-subheading">Scope of Information</h3>
        <p class="disclaimer-content">Accuracy and Reliability: While AXN.ai strives to provide accurate and reliable AI-powered services, the platform cannot guarantee the absolute accuracy, completeness, or suitability of its services for every user's specific needs or circumstances. Users understand that AI tools have inherent limitations and potential inaccuracies.</p>
        <p class="disclaimer-content">Third-Party Content: AXN.ai may provide access to third-party content and services. The platform disclaims any liability for the accuracy, reliability, or legality of third-party content, as users interact with such content at their own discretion and risk.</p>
        <p class="disclaimer-content">User Responsibility: Users are solely responsible for the accuracy, legality, and appropriateness of the input, content, and interactions they engage in within AXN.ai platform. The platform disclaims any liability for user-generated content, interactions, or related decisions.</p>
        <p class="disclaimer-content">No Outcome Guarantees: Users acknowledge that while AXN.ai aims to deliver high-quality AI-powered services, the platform does not guarantee specific outcomes, results, or performance. AI tools serve as aids and do not replace individual judgment or professional advice.</p>
        <p class="disclaimer-content">Indemnification and Non-Liability:  Users agree to indemnify and hold AXN.ai, its subsidiaries, affiliates, employees, and agents harmless from any claims, losses, damages, or liabilities arising from their use of the platform's services. AXN.ai does not assume liability for any indirect, incidental, consequential, or punitive damages.</p>
        <p class="disclaimer-content">Legal Compliance and Jurisdiction: Users’ use of AXN.ai's services is subject to compliance with all applicable laws and regulations. Legal disputes related to the platform's services and this disclaimer fall under the jurisdiction of Delaware, US.</p>
        <p class="disclaimer-content">Acknowledgment and Acceptance: By using AXN.ai's services, users explicitly acknowledge and accept the provisions outlined in this general disclaimer. They agree to release AXN.ai from any and all obligations, liabilities, violations, or lawsuits to the fullest extent permitted by law.</p>
        <p class="disclaimer-content">Informational Purpose: The content provided by AXN.AI on its website and mobile application is solely for general informational purposes. It is not intended as, nor should it be considered as, professional or expert advice.</p>
        <p class="disclaimer-content">No Warranty on Information: While we endeavor to keep the information up to date and correct, AXN.AI makes no representations, warranties, or guarantees, express or implied, as to the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the website or mobile application.</p>

        <h3 class="disclaimer-subheading">User Responsibility</h3>
        <p class="disclaimer-content">Accuracy and Legality of User Input: Users are responsible for the accuracy, legality, and ethical nature of the input, content, and interactions they provide within AXN.ai's platform. This includes ensuring that any content uploaded or communicated complies with copyright laws, does not infringe on the intellectual property rights of others, and adheres to applicable regulations and guidelines.</p>
        <p class="disclaimer-content">Informed Decision-Making: Users are encouraged to exercise informed judgment when utilizing AXN.ai's services. While the platform provides AI-enabled tools and assistance, users recognize that their decisions and actions based on the use of the services are their own responsibility.</p>
        <p class="disclaimer-content">Data Privacy and Security: Users must ensure the confidentiality and security of any sensitive information or data shared with AXN.ai. It is the user’s responsibility to comply with data privacy regulations and safeguard their personal and organizational data.</p>
        <p class="disclaimer-content">Compliance with Policies and Guidelines: Users agree to comply with AXN.ai's terms of service, community guidelines, and all applicable laws and regulations when using the platform's services. This includes refraining from engaging in any malicious, fraudulent, or illegal activities that could compromise the integrity of the platform and its community.</p>
        <p class="disclaimer-content">Ethical Use and Conduct: Users are responsible for maintaining ethical conduct when interacting with AI entities such as Hannah and utilizing the platform's features. This involves refraining from abusive, discriminatory, or inappropriate behavior in all forms of communication and engagement.</p>
        <p class="disclaimer-content">Legal Compliance and Acknowledgment: By using AXN.ai's services, users explicitly acknowledge and accept their responsibility to comply with the outlined user responsibilities. They agree to release AXN.ai from any and all obligations, liabilities, violations, or lawsuits arising from their failure to meet these responsibilities to the fullest extent permitted by law.</p>
        <p class="disclaimer-content">Verification and Use: Users are advised to independently verify any information obtained from our website and mobile application before acting upon it. The use of any information from AXN.AI is solely at the user's own risk.</p>

        <h3 class="disclaimer-subheading">External Links Disclaimer</h3>
        <p class="disclaimer-content">Third-Party Links: Third-Party Content and Integration: AXN.ai may provide access to external websites, platforms, or content through hyperlinks, integrations, or references. The inclusion of external links does not imply endorsement or validation of the content, accuracy, or legality of third-party websites or platforms. Users acknowledge that they interact with external links at their own discretion and risk.</p>
        <p class="disclaimer-content">Responsibility for External Interactions: Users are responsible for any interactions, engagements, or transactions with third-party websites or content accessed through external links provided by AXN.ai.  The platform disclaims any liability for the outcomes of user interactions with external entities beyond its control.</p>
        <p class="disclaimer-content">Content Verification and Compliance:  While AXN.ai makes efforts to ensure the relevance and credibility of external links, users are encouraged to verify the accuracy, reliability, and legality of content accessed through external sources. AXN.ai disclaims any responsibility for the content and operations of external websites or platforms.</p>
        <p class="disclaimer-content">Data Privacy and Security: Users are urged to review the privacy policies and terms of use of external websites or platforms to understand their data practices and security measures. AXN.ai disclaims any liability for the data privacy and security practices of external entities.</p>
        <p class="disclaimer-content">Legal Compliance and Jurisdiction: Users' interactions with external links and third-party content are subject to compliance with the terms of use, policies, and regulations of the respective external entities. Any legal disputes related to external links fall under the jurisdiction of the relevant third-party websites or platforms.</p>
        <p class="disclaimer-content">Acknowledge and Acceptance: By accessing external links provided by AXN.ai, users expressly acknowledge and accept the provisions outlined in this external links disclaimer. They agree to release AXN.ai from any and all obligations, liabilities, violations, or lawsuits in relation to their interactions with external entities to the fullest extent permitted by law.</p>
        <p class="disclaimer-content">External Sources: The AXN.AI website and mobile application may contain links to external websites or content originating from third parties, as well as links to websites and features in banners or other forms of advertising.</p>
        <p class="disclaimer-content">No Endorsement or Verification: We do not investigate, monitor, or verify the accuracy, adequacy, validity, reliability, availability, or completeness of any third-party content. AXN.AI does not endorse, guarantee, or assume responsibility for the information provided by third-party websites or for any products or services offered by third parties.</p>

        <h3 class="disclaimer-subheading">User Discretion</h3>
        <p class="disclaimer-content">Content Evaluation: Users are encouraged to exercise sound judgment and discretion when interacting with AI tools, third-party content, and external links provided by AXN.ai. It is essential for users to independently evaluate the relevance, accuracy, and legality of the content and information they access or generate within the platform.</p>
        <p class="disclaimer-content">Risk Awareness: Users should be aware of the inherent risks associated with interacting with AI-powered features and engaging with third-party content. While AXN.ai strives to provide a secure environment, users acknowledge that AI tools, external links, and third-party content may pose risks and limitations.</p>
        <p class="disclaimer-content">Privacy and Data Security: Users are advised to consider the privacy and data security implications of their interactions and engagements within AXN.ai. It is crucial for users to exercise discretion in sharing sensitive information and to review the platform's privacy and security measures.</p>
        <p class="disclaimer-content">Informed Engagement: Users should engage with the understanding that the platform's AI tools, external links, and third-party content serve as aids but do not replace individual judgment, professional advice, or ethical conduct. It is the user's responsibility to make informed decisions and seek professional guidance when necessary.</p>
        <p class="disclaimer-content">Acknowledgment and Acceptance: By utilizing AXN.ai's services, users explicitly acknowledge and accept the importance of exercising discretion and responsibility in their interactions with the platform's features and content. Users agree to release AXN.ai from any and all obligations, liabilities, violations, or lawsuits in relation to their discretion-related decisions to the fullest extent permitted by law.</p>
        <p class="disclaimer-content">Compliance with Terms: Users are expected to comply with the terms of service, privacy policies, and community guidelines established by AXN.ai. This includes exercising discretion within the boundaries of ethical and legal standards and respecting the rights and privacy of others within the platform's community.</p>
        <p class="disclaimer-content">Caution Advised: Users are cautioned to use their own judgment and conduct due diligence before engaging with any external sites or third-party providers.</p>
        <p class="disclaimer-content">No Involvement in Third-Party Transactions: AXN.AI is not responsible for monitoring transactions or interactions between users and third-party providers of products or services.</p>

        <p class="disclaimer-content">This section emphasizes the importance of user discretion and responsibility when engaging with AXN.ai's AI-powered features, external content, and third-party interactions. It aims to ensure transparency and user understanding within the Disclaimer, while promoting ethical conduct and informed decision-making.</p>

        <h3 class="disclaimer-subheading">Professional Disclaimer</h3>
        <p class="disclaimer-content">Supportive Technology: AXN.ai's AI-powered services and tools, including Alice and Hannah, are designed to support and enhance professional activities such as meeting attendance, transcription, translation, and communication. However, they are not a substitute for professional judgment, advice, or expertise.</p>
        <p class="disclaimer-content">Professional Advice and Decision-Making: Users should be aware that while AXN.ai's services are valuable aids, they do not replace the need for individual professional judgment, legal, medical, financial, or other professional advice. Users are encouraged to seek professional expertise when making critical decisions.</p>
        <p class="disclaimer-content">User Evaluation and Application: Professionals utilizing AXN.ai's services bear the responsibility to evaluate, interpret, and apply the information provided by the platform's AI tools in accordance with their professional knowledge, expertise, and ethical standards specific to their field.</p>
        <p class="disclaimer-content">Non-Guarantee of Professional Outcomes: AXN.ai does not guarantee specific professional outcomes, results, or performance from the use of its AI-powered services. The platform acknowledges that individual professional judgment and expertise play a critical role in achieving professional objectives.</p>
        <p class="disclaimer-content">Indemnification and Non-Reliance: Users agree to indemnify and hold AXN.ai, its subsidiaries, affiliates, employees, and agents harmless from any claims, losses, damages, or liabilities arising from their reliance on the platform's services as a replacement for professional advice. AXN.ai disclaims any liability for the outcomes of professional decisions made based on the use of its services.</p>
        <p class="disclaimer-content">Legal Compliance and Acknowledgment: By utilizing AXN.ai's services for professional purposes, users explicitly acknowledge and accept the provisions outlined in this professional disclaimer. They agree to release AXN.ai from any and all obligations, liabilities, violations, or lawsuits related to their professional use of the platform to the fullest extent permitted by law.</p>

        <p class="disclaimer-content">This comprehensive section aims to emphasize the supportive nature of AXN.ai's AI-powered services while highlighting the importance of professional expertise and judgment in decision-making. It aims to ensure transparency and user understanding within the Disclaimer, promoting ethical and informed professional conduct.</p>

        <h3 class="disclaimer-subheading">Limitation on Professional Advice</h3>
        <p class="disclaimer-content">AXN.ai aims to provide valuable AI-powered services, including media recording, transcription, translation, and communication tools such as Alice and Hannah. However, it's important to note the following limitations on professional advice when using our services:</p>
        <p class="disclaimer-content">Supportive Nature of Services: Users acknowledge that while AXN.ai's services may provide valuable support and assistance, they are not intended to replace professional judgment, advice, or expertise specific to fields such as legal, medical, financial, or other professional domains.</p>
        <p class="disclaimer-content">Encouragement of Professional Consultation: Users are strongly encouraged to seek professional expertise and advice tailored to their specific needs and circumstances when making significant decisions. Our services should be utilized as aids to professional judgment rather than substitutes for it.</p>
        <p class="disclaimer-content">User Evaluation and Application: Professionals utilizing AXN.ai's services assume the responsibility to evaluate, interpret, and apply the information provided within the context of their professional knowledge, expertise, and ethical standards within their respective fields.</p>
        <p class="disclaimer-content">No Guarantee of Professional Outcomes: AXN.ai does not guarantee specific professional outcomes, results, or performance from the use of its services. Users understand and acknowledge that individual professional judgment and expertise play a crucial role in achieving professional objectives.</p>
        <p class="disclaimer-content">Indemnification and Non-Reliance: Users agree to indemnify and ho ld AXN.ai, its subsidiaries, affiliates, employees, and agents harmless from any claims, losses, damages, or liabilities arising from their reliance on the platform's services as a replacement for professional advice. AXN.ai disclaims any liability for the outcomes of professional decisions made based on the use of its services.</p>
        <p class="disclaimer-content">Legal Compliance and Acknowledgment: By utilizing AXN.ai's services for professional purposes, users explicitly acknowledge and accept the limitations on professional advice outlined in this section. They agree to release AXN.ai from any and all obligations, liabilities, violations, or lawsuits related to their professional use of the platform to the fullest extent permitted by law.</p>
        <p class="disclaimer-content">No Professional-Client Relationship: The use of AXN.AI’s services, including the implementation of any suggestions or the utilization of resources available through these services, does not establish a professional-client relationship between the user and AXN.AI or any of its professionals.</p>

        <p class="disclaimer-content">This detailed section underscores the supportive nature of AXN.ai's services and the crucial role of professional judgment and guidance in decision-making, ensuring transparency and legal compliance within the Terms of Service disclaimer.</p>

        <h3 class="disclaimer-subheading">User Responsibility and Caution</h3>
        <p class="disclaimer-content">Informed Decision-Making: Users are responsible for making informed decisions when utilizing AXN.ai's AI-powered services. It is crucial for users to evaluate the suitability of the services for their specific needs and to exercise caution when making decisions based on the information provided.</p>
        <p class="disclaimer-content">Accuracy of User Input: Users bear the responsibility for ensuring the accuracy, legality, and ethical nature of the input, content, and interactions they provide within AXN.ai's platform. It is essential to verify the integrity of the information shared and to exercise caution to avoid misrepresentation or infringement.</p>
        <p class="disclaimer-content">Data Privacy and Security: Users must prioritize the confidentiality and security of any sensitive information or data shared within AXN.ai's platform. It is imperative to be cautious when sharing personal or organizational data and to comply with data privacy regulations and guidelines.</p>
        <p class="disclaimer-content">Ethical Use of AI Tools: Users are expected to engage with AXN.ai's AI tools, such as Hannah, in an ethical manner, refraining from any abusive, discriminatory, or inappropriate behavior. Caution should be exercised to ensure respectful and responsible interactions.</p>
        <p class="disclaimer-content">Compliance with Policies and Laws: Users agree to comply with AXN.ai's terms of service, community guidelines, and all applicable laws and regulations. It is the user's responsibility to exercise caution and adhere to ethical and legal standards within the platform's community.</p>
        <p class="disclaimer-content">Indemnification and Non-Liability: By using AXN.ai's services, users explicitly acknowledge and accept their responsibility and the need for caution outlined in this section. They agree to release AXN.ai from any and all obligations, liabilities, violations, or lawsuits related to their responsibilities and cautionary actions to the fullest extent permitted by law.</p>
        <p class="disclaimer-content">Independent Judgment: Users are encouraged to seek independent professional advice for their specific situations. Reliance on any information provided by AXN.AI is solely at the user's own risk.</p>
        <p class="disclaimer-content">No Liability for Decisions: AXN.AI accepts no liability for any loss or damage incurred as a result of using or relying on the information and services provided. This includes, but is not limited to, errors, omissions, or inaccuracies in the service content.</p>

        <p class="disclaimer-content">This comprehensive section emphasizes user responsibility, informed decision-making, and the need for caution when engaging with AXN.ai's AI-powered platform, ensuring transparency, legal compliance, and user understanding within the Disclaimer.</p>

        <h3 class="disclaimer-subheading">Transcription Accuracy Disclaimer</h3>
        <p class="disclaimer-content">Nature of Transcription Services</p>
        <p class="disclaimer-content">Method of Transcription: The transcription services provided by AXN.AI are based on automated processes and/or human input. While we strive for accuracy, these methods are inherently subject to potential errors and inaccuracies.</p>
        <p class="disclaimer-content">No Guarantee of Accuracy: AXN.AI does not guarantee the accuracy, completeness, or reliability of transcriptions provided. Errors may occur, and the information provided by our transcription service should be independently verified by users.</p>
        <p class="disclaimer-content">User Responsibility</p>
        <p class="disclaimer-content">Review and Verification: Users are responsible for reviewing and verifying the accuracy of transcriptions before using or relying on them for any decision-making or other purposes.</p>
        <p class="disclaimer-content">Limitation of Liability: AXN.AI shall not be liable for any inaccuracies or errors in the transcriptions, nor for any loss or damage incurred as a result of relying on these transcriptions.</p>

        <h3 class="disclaimer-subheading">Use at Your Own Risk Disclaimer</h3>
        <p class="disclaimer-content">Nature of Service and Risks</p>
        <p class="disclaimer-content">Understanding of AI-Powered Services: Users acknowledge and understand that the AI-powered services provided by AXN.ai, including media recording, transcription, translation, summarization, and communication with AI entities such as Hannah, are to be utilized at their own risk.</p>
        <p class="disclaimer-content">Limitations and Inherent Risks: Users recognize that AI technology, while designed to provide support and assistance, may have limitations and inherent risks. These risks may include the potential for inaccuracies, misinterpretations, or limitations in the scope of understanding user input.</p>
        <p class="disclaimer-content">Responsibility for Decisions and Actions: Users accept full responsibility for the decisions and actions they make based on the use of AXN.ai's services. They understand that the platform's AI tools and features serve as aids but do not replace individual judgment, professional advice, or ethical conduct.</p>
        <p class="disclaimer-content">Third-Party Interactions: Users acknowledge that any interactions with third-party content, external links, or external platforms facilitated through AXN.ai's services are at their own discretion and risk. The platform disclaims any liability for the outcomes of such interactions.</p>
        <p class="disclaimer-content">Data Privacy and Security Considerations: Users are responsible for evaluating and managing the privacy and security implications of their interactions within AXN.ai's platform, including the sharing of personal or sensitive information. They understand the importance of exercising discretion and due diligence in this regard.</p>
        <p class="disclaimer-content">Legal Compliance and Jurisdiction: Users' acceptance of the use at their own risk disclaimer signifies their acknowledgment and acceptance of the provisions outlined in this section. They agree to release AXN.ai from any and all obligations, liabilities, violations, or lawsuits related to their use of the platform to the fullest extent permitted by law.</p>
        <p class="disclaimer-content">Review and Acceptance of Terms: By engaging with AXN.ai's services, users explicitly acknowledge and accept the use at your own risk disclaimer, understanding the potential limitations and risks associated with utilizing AI-powered services and features.</p>

        <h3 class="disclaimer-subheading">User Responsibility</h3>
        <p class="disclaimer-content">The use of AXN.AI’s services, including all content and functions available through our website and mobile application, is at the user's sole risk. Users are responsible for understanding the nature of the services and assessing their suitability for their needs.</p>
        <p class="disclaimer-content">Service Provided "as is": AXN.AI’s services are provided on an "AS IS" and "AS AVAILABLE" basis. This means that we do not represent or warrant to the reliability, effectiveness, or appropriateness of our services for any particular purpose.</p>

        <p class="disclaimer-content">This detailed section emphasizes the recognition of inherent risks and personal responsibilities associated with using AXN.ai's AI-powered services, aiming to provide legal protection while ensuring transparency and user understanding within the Terms of Service disclaimer.</p>

        <h3 class="disclaimer-subheading">Absence of Warranties</h3>
        <p class="disclaimer-content">No Implied Warranties: The service is offered without any warranties of any kind, either express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance.</p>
        <p class="disclaimer-content">No Warranty of Uninterrupted Use: We do not warrant that the use of our service will be uninterrupted, timely, secure, or error-free, nor do we warrant that any information obtained through our service will be accurate or reliable.</p>
        <p class="disclaimer-content">Service Nature and Limitations: AXN.ai provides AI-enabled services for media recording, transcription, translation, and summarization, as well as communication tools such as Alice and Hannah. Users acknowledge and agree that the services are provided on an "as is" and "as available" basis, with all associated limitations and potential inaccuracies inherent in AI technology.</p>
        <p class="disclaimer-content">No Express or Implied Warranties: AXN.ai expressly disclaims all warranties, whether express, implied, statutory, or otherwise, including but not limited to any warranties of accuracy, reliability, completeness, merchantability, non-infringement, or fitness for a particular purpose.</p>
        <p class="disclaimer-content">Third-Party Content and Integration: The platform does not warrant the accuracy, reliability, or legality of any third-party content or integration accessed through AXN.ai's services. Users understand that any engagement with third-party content or platforms is at their own risk and discretion.</p>
        <p class="disclaimer-content">Professional and User Responsibilities: Users acknowledge their responsibility to independently evaluate, verify, and validate the suitability of the services and content provided by AXN.ai for their specific needs and professional requirements. Additionally, users bear the responsibility for the accuracy, legality, and ethical nature of their interactions within the platform.</p>
        <p class="disclaimer-content">No Guarantees of Service Continuity: AXN.ai does not warrant that the services will be uninterrupted, timely, secure, error-free, or free from any harmful components, including viruses or malware. Users understand and accept the inherent risks associated with the use of online services.</p>
        <p class="disclaimer-content">Legal Compliance and Acknowledgment: By using AXN.ai's services, users explicitly acknowledge and accept the absence of warranties as outlined in this section and agree to release AXN.ai from any and all obligations, liabilities, violations, or lawsuits related to the absence of warranties to the fullest extent permitted by law.</p>
        <p class="disclaimer-content">This comprehensive section aims to clarify the absence of warranties associated with using AXN.ai's AI-powered services, while emphasizing user understanding and legal compliance within the Terms of Service disclaimer.</p>

        <h3 class="disclaimer-subheading">Limitation of Liability</h3>
        <p class="disclaimer-content">Service Limitations: AXN.ai strives to provide reliable and accurate AI-enabled services; however, it cannot guarantee the complete accuracy, reliability, or suitability of the services for every user's specific needs or circumstances. Users acknowledge and understand that the use of AI tools and services inherently involves certain limitations and potential inaccuracies.</p>
        <p class="disclaimer-content">Third-Party Content and Integration:  AXN.ai may provide access to or integrate with third-party content, platforms, or services. The platform is not liable for the accuracy, reliability, or legality of third-party content, and users interact with such content at their own risk.</p>
        <p class="disclaimer-content">Responsibility for User Input and Content: Users are solely responsible for the accuracy, legality, and appropriateness of the input, content, and interactions they provide within AXN.ai's platform. The platform is not liable for any user-generated content, interactions, or decisions made based on the use of AXN.ai's services.</p>
        <p class="disclaimer-content">No Guarantees of Outcomes: While AXN.ai strives to deliver high-quality AI-powered services, the platform does not guarantee specific outcomes, results, or performance from the use of its tools and features. Users acknowledge that AI tools provide support and assistance but do not replace individual judgment or professional advice.</p>
        <p class="disclaimer-content">Indemnification and Non-Liability: Users agree to indemnify and hold AXN.ai, its subsidiaries, affiliates, employees, and agents harmless from any claims, losses, damages, or liabilities arising from their use of the platform's services. AXN.ai assumes no liability for any indirect, incidental, consequential, or punitive damages related to the use of its services.</p>
        <p class="disclaimer-content">Legal Compliance and Jurisdiction:  Users' use of AXN.ai's services is subject to compliance with all applicable laws and regulations. Additionally, any legal disputes relating to the platform's services and these terms are subject to the jurisdiction of Delaware, US.</p>
        <p class="disclaimer-content">Review and Acceptance of Terms: By using AXN.ai's services, users expressly acknowledge and accept the limitations of liability outlined in these terms and agree to release AXN.ai from any and all obligations, liabilities, violations, or lawsuits to the fullest extent permitted by law.</p>
        <p class="disclaimer-content">Exclusion of Liability: Under no circumstances will AXN.AI, its affiliates, or their respective officers, employees, agents, or licensors be liable for any direct, indirect, incidental, special, consequential, or punitive damages resulting from the use or inability to use our services.</p>
        <p class="disclaimer-content">Acknowledgment by Users: By using AXN.AI’s services, users acknowledge and agree that any material downloaded or otherwise obtained through the use of our services is done at their own discretion and risk, and they will be solely responsible for any damage to their computer system or loss of data that results from the download of such material.</p>

        <p class="disclaimer-content">This comprehensive section aims to clarify the limitations of liability associated with using AXN.ai's AI-powered services, aiming to provide legal protection while ensuring transparency and user understanding within the Terms of Service disclaimer. Always seek legal counsel to ensure proper integration and alignment of these terms within the overall disclaimer.</p>

        <h3 class="disclaimer-subheading">Contact Us</h3>
        <p class="disclaimer-content">For any questions or concerns regarding this disclaimer, please contact us at support@axn.ai</p>

    </div>
  );
};

export default Disclaimer;